/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import {
    searchTimeOut,
    PageLimitChangeCalculation,
    PageClickCalculation,
    FiltersData,
} from '@constants/general.const';
import { routesPath } from '@constants/routesNavigate';

import JobNotFound from '@images/job-not-found.svg';

import { clearResponse } from '@redux/app/appSlice';

import JobGrid from '@app/JobList/jobGrid';
import JobCollapse from '@app/JobList/jobCollapse';
import ListViewJob from '@app/JobList/listViewJob';
import { offerData } from '@app/OffersList/functions';
import AddOfferPopup from '@app/OffersList/addOfferPopup';
import { jobsList } from '@app/JobList/functions';
import { updateIsLogOutUser } from '@app/Auth/Login/loginSlice';
import { setSearchText } from '@redux/app/appSlice';
import OfferHeaderFilters from '@app/OffersList/OfferHeaderFilters';

import '@app/JobList/jobList.scss';

import NotFoundPages from '../../components/NotFoundPages/notFoundPages';
import { Tab, Tabs } from 'react-bootstrap';

const JobListing = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    const { common, jobsData } = useSelector((state: any) => state);
    const { response, searchText } = common;
    const { isJobsList, isExportJobsList } = jobsData;

    const [currentPageData, setCurrentPageData] = useState<number[] | null>(
        null,
    );
    const [pageCount, setPageCount] = useState<number>(1);
    const [totalDataCount, setTotalDataCount] = useState<number>(0);
    const [filters, setFilters] = useState<any>();
    const [offerId, setOfferId] = useState<any>(null);
    const [showViewPopup, setShowViewPopup] = useState<boolean>(false);
    const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
    const [offerDataObj, setOfferDataObj] = useState<any>();
    const [temp, setTemp] = useState<boolean>(!searchText);
    const [showAddPopup, setShowAddPopup] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<string>('all');

    const handleAddClose = () => setShowAddPopup(false);
    const handleAddShow = () => setShowAddPopup(true);

    const handleEditClose = () => {
        setShowEditPopup(false);
        setOfferDataObj(null);
        setOfferId(null);
    };

    const handleViewShow = (offerIdForView: string) => {
        setShowViewPopup(true);
        getOfferData(offerIdForView);
        setOfferId(offerIdForView);
    };

    const handleEditShow = (offerIdForView: string) => {
        setShowViewPopup(false);
        setShowEditPopup(true);
        setOfferId(offerIdForView);
    };

    useEffect(() => {
        const { status, message } = response?.data || {};
        const toastType = status && message ? toast.success : toast.error;
        toastType(message || response?.message);
        dispatch(clearResponse());
        getFilters();
    }, []);

    useEffect(() => {
        let getData: NodeJS.Timeout | undefined;

        if (!temp) {
            getData = setTimeout(() => {
                getJobList(1, filters);
            }, searchTimeOut);
        } else {
            setTemp(false);
        }

        return () => {
            if (!temp && getData) {
                clearTimeout(getData);
            }
        };
    }, [searchText]);

    const updateSearchText = async (text: any) => {
        await dispatch(setSearchText(text));
    };

    useEffect(
        () => () => {
            updateSearchText('');
        },
        [],
    );

    const getFilters = async () => {
        FiltersData(
            searchText,
            getJobList,
            setFilters,
            'job_list',
            dispatch,
            navigate,
        );
    };

    const getJobList = async (currentPage: number, res: any) => {
        const { payload } = await dispatch(
            jobsList({
                filters: {
                    grid: res?.grid ? res?.grid : false,
                    searchText: res?.searchText ? res?.searchText : null,
                    perPageLimit: res?.perPageLimit ? res?.perPageLimit : 5,
                    sortValue: res?.sortValue ? res?.sortValue : 'offeredOn',
                    sortText: res?.sortText ? res?.sortText : 'Received Date',
                    recentActivityValue: res?.recentActivityValue
                        ? res?.recentActivityValue
                        : 'last_year',
                    recentActivityText: res?.recentActivityText
                        ? res?.recentActivityText
                        : 'Last Year',
                    orderValue: res?.orderValue ? res?.orderValue : 'DESC',
                    currentPage:
                        currentPage === 0 || !currentPage ? 1 : currentPage,
                    compact: res?.grid ? false : res?.compact,
                    filterValue: res?.filterValue ? res?.filterValue : 'none',
                    filterText: res?.filterText ? res?.filterText : 'None',
                },
                searchText: !temp ? searchText : null,
                type: res?.type ? res?.type : 'all',
            }),
        );
        if (payload?.data?.message !== '' && payload?.data?.status) {
            setFilters(payload?.data?.filters);
            setCurrentPageData(payload?.data?.data);
            setPageCount(payload?.data?.meta?.last_page);
            setTotalDataCount(payload?.data?.meta?.total);
        } else {
            handlePayloadError(payload);
        }
    };

    const getOfferData = async (offerIdForView: any) => {
        const { payload } = await dispatch(offerData(offerIdForView));
        if (payload?.data?.message !== '' && payload?.data?.status) {
            setOfferDataObj(payload?.data?.data);
        } else {
            if (payload?.logout) {
                localStorage.removeItem('accessToken');
                dispatch(updateIsLogOutUser());
                navigate(routesPath?.login);
            }
        }
    };

    const handlePayloadError = (payload: any) => {
        toast.error(payload?.message);
        if (payload?.logout) {
            localStorage.removeItem('accessToken');
            dispatch(updateIsLogOutUser());
            navigate(routesPath?.login);
        }
    };

    const handlePageClick = ({ selected: selectedPage }: any) => {
        var updateTypeInFilter = {
            ...filters,
            type: filters?.compact ? selectedTab : false,
        };

        var updateFilter = PageClickCalculation(
            updateTypeInFilter,
            selectedPage,
        );
        getJobListAndUpdateFilter(updateFilter);
    };

    const handleSortChange = async (sort: string, textShow: string) => {
        if (sort) {
            var updateFilter = {
                ...filters,
                sortValue: sort,
                sortText: textShow,
                type: filters?.compact ? selectedTab : false,
            };
            getJobListAndUpdateFilter(updateFilter);
        }
    };

    const handleFilterChange = async (filter: string, textShow: string) => {
        if (filter) {
            var updateFilter = {
                ...filters,
                filterValue: filter,
                filterText: textShow,
                type: filters?.compact ? selectedTab : false,
            };
            getJobListAndUpdateFilter(updateFilter);
        }
    };
    const handleRecentActivityChange = async (
        sort: string,
        textShow: string,
    ) => {
        if (sort) {
            var updateFilter = {
                ...filters,
                recentActivityValue: sort,
                recentActivityText: textShow,
                type: filters?.compact ? selectedTab : false,
            };
            getJobListAndUpdateFilter(updateFilter);
        }
    };

    const handleLimitChange = async (limit: number) => {
        var updateTypeInFilter = {
            ...filters,
            type: filters?.compact ? selectedTab : false,
        };

        var updateFilter = PageLimitChangeCalculation(
            updateTypeInFilter,
            totalDataCount,
            limit,
        );
        getJobListAndUpdateFilter(updateFilter);
    };

    const handleOrderChange = async (order: string) => {
        if (order) {
            var updateFilter = {
                ...filters,
                orderValue: order,
                type: filters?.compact ? selectedTab : false,
            };
            getJobListAndUpdateFilter(updateFilter);
        }
    };

    const handleGrid = (gridStatus: boolean) => {
        var updateFilter = {
            ...filters,
            grid: gridStatus,
            compact: false,
            type: false,
        };
        getJobListAndUpdateFilter(updateFilter);
        setSelectedTab('all');
    };

    const handleCompactView = (gridStatus: boolean) => {
        var updateFilter = {
            ...filters,
            grid: gridStatus ? false : true,
            compact: gridStatus,
            type: false,
        };
        getJobListAndUpdateFilter(updateFilter);
        setSelectedTab('all');
    };

    const handleIncompleteJob = (gridStatus: boolean, type: any) => {
        var updateFilter = {
            ...filters,
            grid: gridStatus ? false : true,
            compact: gridStatus,
            type: type,
        };
        getJobListAndUpdateFilter(updateFilter);
    };

    const getJobListAndUpdateFilter = (updateFilter: any) => {
        getJobList(updateFilter.currentPage, updateFilter);
        setFilters(updateFilter);
    };

    const handelViewShow = (offerDataObjs: any) => {
        setShowViewPopup(true);
    };

    const handleViewClose = () => {
        setShowViewPopup(false);
        setOfferDataObj(null);
    };

    return (
        <div className="mfnw__joblisting_content">
            {filters && (
                <OfferHeaderFilters
                    totalDataCount={totalDataCount}
                    filters={filters}
                    handleLimitChange={handleLimitChange}
                    handleSortChange={handleSortChange}
                    handleRecentActivityChange={handleRecentActivityChange}
                    handleFilterChange={handleFilterChange}
                    handleOrderChange={handleOrderChange}
                    handleGrid={handleGrid}
                    handleCompactView={handleCompactView}
                    handleAddShow={handleAddShow}
                    titleText={'Job List'}
                    btnText={'Add New Job'}
                />
            )}
            {isJobsList ? (
                <div className="text-center mx-4">
                    <span className="ms-5 text-center">
                        <LoaderBtn size="sm" />
                    </span>
                </div>
            ) : (
                <>
                    {filters?.compact ? (
                        <div className="mfnw__dashboard-content setting-list">
                            <Tabs
                                defaultActiveKey={selectedTab}
                                onSelect={(e: any) => {
                                    setSelectedTab(e);
                                    handleIncompleteJob(true, e);
                                }}
                                className="mfnw__dashboard_linetab flex-wrap"
                            >
                                <Tab eventKey="all" title="All" className="">
                                    <JobCollapse
                                        currentPageData={currentPageData}
                                        handleViewShow={handleViewShow}
                                        isExport={false}
                                        filters={filters}
                                        isExportJobsList={isExportJobsList}
                                        handlePayloadError={handlePayloadError}
                                    />
                                </Tab>
                                <Tab
                                    eventKey="incomplete"
                                    title="Incomplete"
                                    className=""
                                >
                                    <JobCollapse
                                        currentPageData={currentPageData}
                                        handleViewShow={handleViewShow}
                                        isExport={true}
                                        filters={filters}
                                        isExportJobsList={isExportJobsList}
                                        handlePayloadError={handlePayloadError}
                                    />
                                </Tab>
                            </Tabs>
                        </div>
                    ) : (
                        currentPageData &&
                        !filters?.compact &&
                        !filters?.grid && (
                            <NotFoundPages
                                currentPageData={currentPageData}
                                image={JobNotFound}
                                message={`    Exciting opportunities are on
                                                the horizon and will be
                                                available soon.`}
                            />
                        )
                    )}
                    {filters?.grid ? (
                        <JobGrid
                            currentPageData={currentPageData}
                            handleViewShow={handleViewShow}
                        />
                    ) : (
                        <>
                            {!filters?.compact && (
                                <ListViewJob
                                    currentPageData={currentPageData}
                                    handleViewShow={handleViewShow}
                                />
                            )}
                        </>
                    )}
                </>
            )}

            <div className="mt-4 float-end">
                {currentPageData && currentPageData.length > 0 && (
                    <ReactPaginate
                        previousLabel={'← Previous'}
                        nextLabel={'Next →'}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        previousLinkClassName={'pagination__link'}
                        nextLinkClassName={'pagination__link'}
                        disabledClassName={'pagination__link--disabled'}
                        activeClassName={'pagination__link--active active'}
                        pageRangeDisplayed={2}
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageLinkClassName="page-link"
                        previousClassName="page-link rounded-start"
                        nextClassName="page-link rounded-end"
                        forcePage={filters?.currentPage - 1}
                    />
                )}
            </div>

            {showEditPopup && (
                <AddOfferPopup
                    showPopup={showEditPopup}
                    handleClose={handleEditClose}
                    handelViewShow={handelViewShow}
                    editData={offerDataObj}
                    isDisabled={false}
                    isEditable={true}
                    listCall={() => getJobList(1, filters)}
                    offerId={offerId}
                    getOfferData={getOfferData}
                    title={'Edit Job'}
                    isJobForm={true}
                />
            )}
            {showViewPopup && (
                <AddOfferPopup
                    showPopup={showViewPopup}
                    handleClose={handleViewClose}
                    handleEditShow={handleEditShow}
                    editData={offerDataObj}
                    isDisabled={true}
                    isEditable={false}
                    offerId={offerId}
                    getOfferData={getOfferData}
                    listCall={() => getJobList(1, filters)}
                    title={'View Job'}
                    isJobForm={true}
                    isAcceptable={false}
                />
            )}
            {showAddPopup && (
                <AddOfferPopup
                    showPopup={showAddPopup}
                    handleClose={handleAddClose}
                    listCall={() => getJobList(1, filters)}
                    isDisabled={false}
                    isAcceptable={false}
                    isJobForm={true}
                    title={'Add Job'}
                />
            )}
        </div>
    );
};

export default JobListing;
