import React, { useState } from 'react';
import moment from 'moment';
import { Col, Dropdown, Form, Row } from 'react-bootstrap';

import DateSelect from '@components/DateSelect/DateSelect';
import DateRangeSelect from '@components/DateRengeSelect/DateRengeSelect';

import { scheduleFilterArray } from '@constants/general.const';

import {
    faBarsFilter,
    faCalendarRange,
    faMagnifyingGlass,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';

const CustomCalendarHeader = ({
    calendarRef,
    setCurrentDate,
    viewName,
    currentDate,
    searchText,
    setSearchText,
    filter,
    filterText,
    setFilterText,
    setFilter,
    isDayGridView,
    setDateRange,
    setSelectedDate,
    dateRange,
    type,
    isDayGridWeekView,
}: any) => {
    const [activeView, setActiveView] = useState<string>('dayGridMonth');

    const today = () => {
        if (calendarRef.current) {
            const api = calendarRef.current.getApi();
            api.today();
            setCurrentDate(api.view.title);
        }
    };

    const changeDate = (direction: any) => {
        if (calendarRef.current) {
            const api = calendarRef.current.getApi();
            if (direction === 'prev') {
                api.prev();
            } else if (direction === 'next') {
                api.next();
            }
            setCurrentDate(api.view.title);
        }
    };

    const changeView = (view: any) => {
        if (calendarRef.current) {
            calendarRef.current.getApi().changeView(view);
            setActiveView(view);
        }
    };

    return (
        <Row className="custom-calendar-header mb-2">
            <Row>
                <p className="text-large fw-semibold mb-1 schedule_title_page">
                    {viewName}
                </p>
            </Row>
            <Col className="col-md-6 col-lg-4 d-flex align-items-center schedule_header_center">
                <div className="fc-header-toolbar fc-toolbar ">
                    <div className="fc-toolbar-chunk ">
                        <button
                            onClick={today}
                            className="fc-today-button fc-button fc-button-primary"
                        >
                            Today
                        </button>
                        <div className="fc-button-group">
                            <button
                                type="button"
                                title="Previous Monthly"
                                onClick={() => changeDate('prev')}
                                aria-pressed="false"
                                className="fc-prev-button fc-button fc-button-primary"
                            >
                                <span className="fc-icon fc-icon-chevron-left"></span>
                            </button>
                            {type === 'packer' && isDayGridWeekView && (
                                <DateRangeSelect
                                    selectsRange={true}
                                    startDate={new Date(dateRange[0])}
                                    endDate={moment(dateRange[1])
                                        .subtract(1, 'days')
                                        .toDate()}
                                    groupInput={true}
                                    icon={faCalendarRange}
                                    onChangeFunc={(update: any) => {
                                        setDateRange(update);
                                    }}
                                    disabled={true}
                                    className="mfnw__login-form-control mfnw__login-form-control-bg me-4"
                                />
                            )}
                            {isDayGridView && (
                                <DateSelect
                                    className="mfnw__login-form-control mfnw__login-form-control-bg"
                                    placeholder="MM/DD/YYYY"
                                    tabIndex={2}
                                    selected={dateRange[0]}
                                    dateFormat="MM/dd/yyyy"
                                    inputName="packDate"
                                    icon={faCalendar}
                                    onChangeFunc={({
                                        value: valueDateChange,
                                    }: any) => {
                                        setDateRange([
                                            valueDateChange,
                                            valueDateChange,
                                        ]);
                                        var dateObj: any = new Date(
                                            valueDateChange,
                                        );
                                        setSelectedDate(dateObj);
                                    }}
                                    groupInput={true}
                                />
                            )}
                            <button
                                type="button"
                                title="Next Monthly"
                                aria-pressed="false"
                                className="fc-next-button fc-button fc-button-primary"
                                onClick={() => changeDate('next')}
                            >
                                <span className="fc-icon fc-icon-chevron-right"></span>
                            </button>
                        </div>
                        <h2 className="fc-toolbar-title">{currentDate}</h2>
                    </div>
                </div>
            </Col>
            <Col className="col-md-6 col-lg-4 d-flex justify-content-center">
                <div className="fc-toolbar-chunk ">
                    <div className="fc-button-group">
                        <button
                            type="button"
                            title="Monthly view"
                            aria-pressed="true"
                            className={`fc-dayGridMonth-button fc-button fc-button-primary ${
                                activeView === 'dayGridMonth'
                                    ? 'fc-button-active'
                                    : ''
                            }`}
                            onClick={() => changeView('dayGridMonth')}
                        >
                            Monthly
                        </button>
                        {(type === 'job' ||
                            type === 'packer' ||
                            type === 'driver') && (
                            <>
                                <button
                                    type="button"
                                    title="Weekly view"
                                    aria-pressed="false"
                                    className={`fc-dayGridMonth-button fc-button fc-button-primary ${
                                        activeView === 'customWeekView'
                                            ? 'fc-button-active'
                                            : ''
                                    }`}
                                    onClick={() => changeView('customWeekView')}
                                >
                                    Weekly
                                </button>
                                <button
                                    type="button"
                                    title="Daily view"
                                    aria-pressed="false"
                                    className={`fc-dayGridMonth-button fc-button fc-button-primary ${
                                        activeView === 'timeGridDay'
                                            ? 'fc-button-active'
                                            : ''
                                    }`}
                                    onClick={() => changeView('timeGridDay')}
                                >
                                    Daily
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </Col>
            <Col className="col-lg-4  d-flex  justify-content-center justify-content-lg-end">
                <div className="d-flex main-par">
                    <div className="main-search-input position-relative">
                        <Form.Control
                            type="search"
                            placeholder="Search"
                            className="mfnw__login-form-control mfnw__search-form-control-bg mb-0"
                            aria-label="Search"
                            value={searchText}
                            onChange={(e: any) => {
                                if (e.target.value !== '') {
                                    setSearchText(e.target.value);
                                } else {
                                    setSearchText('');
                                }
                            }}
                        />
                        <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            color="#b7b7b7"
                            size="lg"
                            className="position-absolute form-control-search"
                        />
                    </div>
                </div>
                <div className="main-search-input">
                    <div className="main-search-input position-relative">
                        <div className="dropdown_filter-top ms-3 filter-form-control-bg">
                            <FontAwesomeIcon
                                icon={faBarsFilter}
                                color="#b7b7b7"
                                size="lg"
                                className="position-absolute form-control-search"
                            />
                            <Dropdown className="ms-2">
                                <Dropdown.Toggle
                                    variant="default"
                                    id="dropdown-basic"
                                    className="limit ms-2"
                                >
                                    {filterText}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {scheduleFilterArray?.map(
                                        (item: any, index: number) => {
                                            const { name, displayName } = item;
                                            return (
                                                <Dropdown.Item
                                                    active={filter === name}
                                                    key={index}
                                                    onClick={() => {
                                                        setFilter(name);
                                                        setFilterText(
                                                            displayName,
                                                        );
                                                    }}
                                                    eventKey={`#/action-${index}`}
                                                >
                                                    {displayName}
                                                </Dropdown.Item>
                                            );
                                        },
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default CustomCalendarHeader;
