import { axiosInterceptor } from './interceptors';

// Retrieves default filters for offers
export const apiGetDefaultFilters = async (data: any) => {
    const response = await axiosInterceptor.get(`/offers/filters/${data}`);
    return response;
};

// Retrieves offer data for editing
export const apiOfferData = async (data: any) => {
    const response = await axiosInterceptor.get(`/offers/${data}/edit`);
    return response;
};

// Updates an offer
export const apiUpdateOffer = async (data: any) => {
    const response = await axiosInterceptor.put(
        `/offers/${data.offers_nano_id}`,
        data,
    );
    return response;
};

// Creates a new offer
export const apiCreateOffer = async (data: any) => {
    const response = await axiosInterceptor.post(`/offers`, data);
    return response;
};

// Checks if a GBL is valid
export const apiCheckGBLValidate = async (data: any) => {
    const response = await axiosInterceptor.post(`/offers/gbl/check`, data);
    return response;
};

// Accepts an offer
export const apiAcceptOffer = async (data: any) => {
    const response = await axiosInterceptor.post(
        `/offers/${data?.id}/${data?.status}`,
    );
    return response;
};

// Rejects an offer
export const apiRejectOffer = async (data: any) => {
    const response = await axiosInterceptor.post(
        `/offers-reject/${data?.offerId}/rejected`,
        data,
    );
    return response;
};

// Retrieves a list of offers
export const apiOfferList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `/offers/list?page=${data?.filters?.currentPage}`,
        data,
    );
    return response;
};

// Retrieves the history of offers
export const apiOfferHistory = async (data: any) => {
    const response = await axiosInterceptor.post(`/offers-history`, data);
    return response;
};

// Retrieves a list of jobs
export const apiJobsList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `/jobs?page=${data?.filters?.currentPage}`,
        data,
    );
    return response;
};

export const apiExportJobsList = async (data: any) => {
    const response = await axiosInterceptor.post(`/export`, data);
    return response;
};

export const apiJobsCalenderList = async (data: any) => {
    const response = await axiosInterceptor.post(`/job/calender-list`, data);
    return response;
};

// Checks zip code lookup
export const apiCheckZipLookUp = async (data: any) => {
    const response = await axiosInterceptor.post(`/zip-lookup`, data);
    return response;
};
