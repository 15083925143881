import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import moment from 'moment';

import { IListOption } from '../Interface/common';
import { IOrderItem } from './general.interface';

import { routesPath } from '@constants/routesNavigate';

import { getDefaultFilters } from '../app/OffersList/functions';
import { updateIsLogOutUser } from '@app/Auth/Login/loginSlice';

export const passwordLength: number = 8;
export const phoneRegex: RegExp = /[^a-zA-Z0-9 ]/g;
export const minPhoneLength: number = 10;
export const phoneLengthCheck: number = 11;

export const searchTimeOut: number = 1000;
export const refreshInterval = 300000;

export const confirmButtonColor: string = '#3085d6';
export const cancelButtonColor: string = '#d33';

export const phoneFormat: string = '(999) 999-9999';
export const dateFormat: string = 'MM/DD/YYYY';
export const dateEndTimeFormat: string = 'MM/DD/YYYY hh:mm A';
export const humanReadableDateFormat: string = 'MMMM Do, YYYY';

export const pageLimitList: number[] = [5, 10, 15, 20];

export const offersStatsArray: IListOption[] = [
    {
        value: 'pending',
        label: 'Pending',
    },
    {
        value: 'accepted',
        label: 'Unscheduled',
    },
    {
        value: 'assigned',
        label: 'Assigned',
    },
    {
        value: 'scheduled',
        label: 'Scheduled',
    },

    {
        value: 'expired',
        label: 'Expired',
    },
    {
        value: 'canceled',
        label: 'Canceled',
    },
    {
        value: 'inSIT',
        label: 'In SIT',
    },
    {
        value: 'sit_delivery',
        label: 'SIT Delivery',
    },
    {
        value: 'completed',
        label: 'Completed',
    },
];
export const jobStatsArray: IListOption[] = [
    {
        value: 'accepted',
        label: 'Unscheduled',
    },
    {
        value: 'assigned',
        label: 'Assigned',
    },
    {
        value: 'scheduled',
        label: 'Scheduled',
    },

    {
        value: 'expired',
        label: 'Expired',
    },
    {
        value: 'canceled',
        label: 'Canceled',
    },
    {
        value: 'inSIT',
        label: 'In SIT',
    },
    {
        value: 'sit_delivery',
        label: 'SIT Delivery',
    },
    {
        value: 'completed',
        label: 'Completed',
    },
];

export const sortList: string[] = ['ASC', 'DESC'];

export const dateFormatPlaceholder: string = '--/--/----';

export const MobileHeaderHeight = '70px';
export const PagePdngTopEqualHeaderHeight = '5.521vw';
export const parallaxTranslateY: [string, string] = ['30px', '-30px'];
export const debounceTime = 500;
export const countingForMb = 1024;
export const uploadDocumentSizeLimit = 2;
export const validType = [
    'image/jpeg',
    'image/pjpeg',
    'image/png',
    'image/webp',
    'application/pdf',
];

export const allowedFileExtensionsForSystemExtensions = {
    'image/jpeg': ['.jpg', '.png', '.pdf'],
};

export const awsImagePath = 'staging/upload/userOrderImagePath/';

export const orderItemArray: IOrderItem[] = [
    {
        name: 'forwarder_name',
        displayName: 'Forwarder',
    },
    {
        name: 'packDate',
        displayName: 'Pack',
    },
    {
        name: 'pickupDate',
        displayName: 'Pickup',
    },
    {
        name: 'deliveryDate',
        displayName: 'RDD',
    },
    {
        name: 'estimatedWeight',
        displayName: 'Weight',
    },
    {
        name: 'miles',
        displayName: 'Miles',
    },
    {
        name: 'origin',
        displayName: 'Origin',
    },
    {
        name: 'destination',
        displayName: 'Destination',
    },
    {
        name: 'offerStatus',
        displayName: 'Status',
    },
    {
        name: 'offeredOn',
        displayName: 'Received Date',
    },
];
export const filtetItemArray: IOrderItem[] = [
    {
        name: 'none',
        displayName: 'None',
    },
    {
        name: 'origin',
        displayName: 'Origin',
    },
    {
        name: 'destination',
        displayName: 'Destination',
    },
    {
        name: 'forwarder_name',
        displayName: 'Forwarder',
    },
    {
        name: 'region_origin',
        displayName: 'Region (Origin)',
    },
    {
        name: 'region_destination',
        displayName: 'Region (Dest.)',
    },
];
export const recentActivity: IOrderItem[] = [
    {
        name: '30',
        displayName: 'Last 30 Days',
    },
    {
        name: '60',
        displayName: 'Last 60 Days',
    },
    {
        name: '90',
        displayName: 'Last 90 Days',
    },
    {
        name: '180',
        displayName: 'Last 180 Days',
    },
    {
        name: 'last_year',
        displayName: 'Last Year',
    },
    {
        name: 'current_year',
        displayName: 'Current Year',
    },
];
export const contactPhoneNoTypes: IListOption[] = [
    {
        value: 'Work',
        label: 'Work',
    },
    {
        value: 'Mobile',
        label: 'Mobile',
    },
];
export const contactPackLoadTypes: IListOption[] = [
    {
        value: 'pack',
        label: 'Pack',
    },
    {
        value: 'load',
        label: 'Load',
    },
];

export const contactUnloadTypes: IListOption[] = [
    {
        value: 'unloading',
        label: 'Unloading',
    },
];
export const contactPackActions: IListOption[] = [
    {
        value: 'pending',
        label: 'Pending',
    },
    {
        value: 'assigned',
        label: 'Assigned',
    },
    {
        value: 'confirmed',
        label: 'Confirmed',
    },
];
export const contactPhoneNoTypes2: IListOption[] = [
    {
        value: 'work',
        label: 'Work',
    },
    {
        value: 'mobile',
        label: 'Mobile',
    },
];

export const agentTypes: IListOption[] = [
    {
        value: 'both',
        label: 'Both',
    },
    {
        value: 'issued',
        label: 'Issued',
    },
    {
        value: 'destination',
        label: 'Destination',
    },
];

export const pageLimitListValue: any = [
    {
        value: 5,
        label: 'Limit: 5',
    },
    {
        value: 15,
        label: 'Limit: 15',
    },
    {
        value: 20,
        label: 'Limit: 20',
    },
];

export const scheduleFilterArray: IOrderItem[] = [
    {
        name: 'all',
        displayName: 'All',
    },
    {
        name: 'assigned',
        displayName: 'Assign',
    },
    {
        name: 'scheduled',
        displayName: 'Scheduled',
    },
    {
        name: 'accepted',
        displayName: 'Unscheduled',
    },
];

export const showDeleteConfirmationDialog = async (data: any) => {
    const options: any = {
        title: data?.title,
        text: data?.text ?? '',
        icon: data?.icon,
        showCancelButton: true,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: data?.confirmButtonText,
    };

    if (data?.html) {
        options.html = data?.html;
    }
    if (data?.cancelButtonText) {
        options.cancelButtonText = data?.cancelButtonText;
    }
    if (data?.customClass) {
        options.customClass = data?.customClass;
    }

    const result = await Swal.fire(options);

    return result.isConfirmed;
};

export const formatDateTOCurrent = (data: any) => {
    const date =
        moment.utc(data).local().format('MM-DD-Y') !== 'Invalid date'
            ? moment.utc(data).local().format('MM-DD-Y')
            : moment();

    return date;
};
export const PageLimitChangeCalculation = (
    filters: any,
    totalDataCount: any,
    limit: any,
) => {
    var updateFilter = { ...filters, perPageLimit: limit };
    if (filters?.currentPage > Math.ceil(totalDataCount / limit)) {
        updateFilter = {
            ...filters,
            currentPage: Math.ceil(totalDataCount / limit),
            perPageLimit: limit,
        };
    }
    return updateFilter;
};

export const PageClickCalculation = (filters: any, selectedPage: any) => {
    var updateFilter = { ...filters, currentPage: selectedPage + 1 };
    return updateFilter;
};

export const FiltersData = async (
    searchText: any,
    getList: any,
    setFilters: any,
    type: string,
    dispatch: any,
    navigate: any,
) => {
    const { payload } = await dispatch(getDefaultFilters(type));

    if (payload?.data?.status) {
        const filters = payload?.data?.filters;
        setFilters(filters);

        const page = searchText ? 1 : filters?.currentPage;

        getList(page, filters);
    } else {
        toast.error(payload?.message);
        if (payload?.logout) {
            localStorage.removeItem('accessToken');
            dispatch(updateIsLogOutUser());
            navigate(routesPath?.login);
        }
    }
};

export const deduplicateList = (list: any[], key: string) =>
    list?.filter(
        (option, index, self) =>
            index === self.findIndex((o) => o[key] === option[key]),
    );
