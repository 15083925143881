/* eslint-disable complexity */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';
import { isEqual } from 'lodash';
import {
    Col,
    Row,
    Modal,
    Form,
    Image,
    Button,
    Tab,
    Tabs,
    Badge,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';

import RadioButton from '@components/RadioButton/RadioButton';
import InputGroupText from '@components/InputGroupText/InputGroupText';
import ReactSelectGroup from '@components/reactSelectGroup/ReactSelectGroup';
import DateSelect from '@components/DateSelect/DateSelect';
import ErrorMsg from '@components/errorMsg';
import SwitchRadioButton from '@components/SwichRadioButton/switchRadioButton';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import { ROUTES } from '@constants/routes';
import {
    cancelButtonColor,
    confirmButtonColor,
    offersStatsArray,
    jobStatsArray,
    searchTimeOut,
    formatDateTOCurrent,
    deduplicateList,
} from '@constants/general.const';
import { routesPath } from '@constants/routesNavigate';

import { yupResolver } from '@hookform/resolvers/yup';

import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { faCirclePlus, faPen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import '@fortawesome/pro-light-svg-icons';

import Destination from '@images/destination1.svg';
import OriginIcon from '@images/origin-icon.svg';

import {
    checkGBLValidate,
    checkZipLookUp,
    createOffer,
    getAllDataList,
    updateOffer,
} from '@app/OffersList/functions';
import AddShipperInformation from '@app/OffersList/addShipperInformation';
import { offerFields, schema, jobSchema } from '@app/OffersList/offers.const';
import ViewNoteListPopup from '@app/JobList/OfferNotes/viewNoteListPopup';
import ViewDocumentListPopup from '@app/JobList/Documents/viewDocumentListPopup';
import JobSchedulePopup from '@app/JobList/jobSchedulePopup';
import TspAddOrEditPopup from '@app/SettingsLists/TSP/tspAddOrEditPopup';

import { IOfferProps } from './offer.interface';
import {
    IListOption,
    IListOptionSearchObj,
    IListOptionSearchObj2,
} from '../../Interface/common';
import AgentsAddOrEditPopup from '../SettingsLists/Agents/agentsAddOrEditPopup';
import CheckBox from '../../components/CheckBox/CheckBox';
import { changeOfferStatus } from './functions';
import ViewHistoryListPopup from '../JobList/History/viewHistoryListPopup';
import { log } from 'console';
import { AnyARecord } from 'dns';

const AddOfferPopup = ({
    showPopup,
    handleClose,
    getOfferData,
    handleEditShow,
    offerId,
    editData,
    listCall,
    isDisabled,
    isAcceptable,
    isJobForm,
    title,
    handleAcceptActions,
    isRejectOffer,
    isEditable,
}: any) => {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const { offersData, login } = useSelector((state: any) => state);
    const { pathname } = useLocation();

    const { authUser } = login;
    const { isCreateOffer, isOfferData } = offersData;

    const [gblCheck, setGblCheck] = useState<string | null>('');
    const [isJob, setIsJob] = useState<boolean>(false);
    const [zipOriError, setZipOriError] = useState<string | null>(null);
    const [zipDestError, setZipDestError] = useState<string | null>(null);
    const [showAgentPopup, setShowAgentPopup] = useState<boolean>(false);
    const [forwarderListOptionArr, setForwarderListOptionArr] = useState<
        IListOption[]
    >([]);
    const [forwarderListCount, setForwarderListCount] = useState<number>(0);

    const [agentListOptionArr, setAgentListOptionArr] = useState<IListOption[]>(
        [],
    );
    const [agentListCount, setAgentListCount] = useState<number>(0);
    const [agentToListOptionArr, setAgentToListOptionArr] = useState<
        IListOption[]
    >([]);
    const [agentToListCount, setAgentToListCount] = useState<number>(0);
    const [cityArr, setCityArr] = useState<IListOption[]>([]);
    const [destCityArr, setDestCityArr] = useState<IListOption[]>([]);
    const [showNoteListPopup, setShowNoteListPopup] = useState<boolean>(false);
    const [showHistoryListPopup, setShowHistoryListPopup] =
        useState<boolean>(false);

    const [totalDocumentDataCount, setTotalDocumentDataCount] =
        useState<number>(0);
    const [totalNoteDataCount, setTotalNoteDataCount] = useState<number>(0);
    const [totalHistoryDataCount, setTotalHistoryDataCount] =
        useState<number>(0);
    const handleNoteListShow = () => setShowNoteListPopup(true);
    const handleNoteListClose = (totalDataCount: number) => {
        setShowNoteListPopup(false);
        setSelectedTab('tsp01');
        setTotalNoteDataCount(totalDataCount);
    };

    const handleHistoryListShow = () => setShowHistoryListPopup(true);
    const handleHistoryListClose = (totalDataCount: number) => {
        setShowHistoryListPopup(false);
        setSelectedTab('tsp01');
        setTotalHistoryDataCount(totalDataCount);
    };
    const [showScheduleListPopup, setShowScheduleListPopup] =
        useState<boolean>(false);

    const isFormSubmitManuallyRef = useRef<boolean>(false);
    const isChangeStatusRef = useRef<boolean>(false);

    const handleScheduleListShow = async () => {
        if (isDirty || agentChange) {
            Swal.fire({
                title: 'Warning',
                html:
                    'Please save your changes before moving to schedule <br/> ' +
                    'Otherwiese all changes made will be lost.',
                allowOutsideClick: false,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: cancelButtonColor,
                confirmButtonColor: confirmButtonColor,
                cancelButtonText: 'No',
                confirmButtonText: 'Save',
            }).then(async (result) => {
                if (result?.isConfirmed) {
                    submitButtonRef?.current?.click();
                    isFormSubmitManuallyRef.current = true;
                    setAgentChange(false);
                }
            });
        } else {
            setShowScheduleListPopup(true);
        }
    };
    const [showViewListPopup, setShowViewListPopup] = useState<boolean>(false);
    const [showEditSchedulePopup, setShowEditSchedulePopup] = useState<boolean>(
        !isDisabled,
    );
    const [showPopupShipper, setShowPopupShipper] = useState<boolean>(false);
    const [shipperName, setShipperName] = useState<string | null>(null);
    const [isShipperSet, setIsShipperSet] = useState<boolean>(false);
    const [shipperNanoId, setShipperNanoId] = useState<string | null>(null);
    const [shippersData, setShippersData] = useState<Array<any> | null>(null);
    const [selectedTab, setSelectedTab] = useState<string>('tsp01');
    const [agentChange, setAgentChange] = useState<boolean>(false);
    const [searchObj, setSearchObj] = useState<IListOptionSearchObj>({
        oriState: '',
        oriCity: '',
        destState: '',
        destCity: '',
    });
    const [searchObj2, setSearchObj2] = useState<IListOptionSearchObj2 | null>(
        null,
    );
    const [showAddTSPPopup, setShowAddTSPPopup] = useState<boolean>(false);
    const [destAgentData, setDestAgentData] = useState<any>(null);
    const [toAgentData, setToAgentData] = useState<any>(null);

    const [
        isInternationalOriginPostalCode,
        setISInternationalOriginPostalCode,
    ] = useState<boolean>(false);
    const [isInternationalPostalCode, setISInternationalPostalCode] =
        useState<boolean>(false);

    const handleAgentClose = () => setShowAgentPopup(false);
    const handleAgentShow = () => setShowAgentPopup(true);
    const handleAddTSPClose = () => setShowAddTSPPopup(false);
    const handleAddTSPShow = () => setShowAddTSPPopup(true);
    const handleViewListShow = () => {
        setShowViewListPopup(true);
    };
    const handleScheduleListClose = () => {
        setShowScheduleListPopup(false);
        setSelectedTab('tsp01');
    };
    const handleCloseShipper = (data: any) => {
        setValue('shipper_info', data);
        setShippersData(data);
        setShowPopupShipper(false);
    };

    const handleShowShipper = async () => {
        if (getValues('shipper')) {
            setShipperName(getValues('shipper'));
            setIsShipperSet(false);
            if (shipperNanoId) {
                setShowPopupShipper(true);
            } else {
                setShowPopupShipper(true);
            }
        } else {
            setIsShipperSet(true);
        }
    };

    const handleViewListClose = (totalDataCount: number) => {
        setSelectedTab('tsp01');
        setShowViewListPopup(false);
        setTotalDocumentDataCount(totalDataCount);
    };

    const {
        handleSubmit,
        control,
        reset,
        watch,
        getValues,
        setValue,
        clearErrors,
        formState: { errors, isDirty },
    } = useForm<IOfferProps>({
        resolver: yupResolver(isJobForm ? jobSchema : schema),
        defaultValues: {
            ...offerFields,
            offerId: offerId ? true : false,
            offerStatus:
                !offerId && isJobForm ? 'accepted' : offerFields.offerStatus,
        },
    });

    useEffect(() => {
        !isDisabled &&
            getAllList(searchObj2, {
                agentIssuedOffset: false,
                agentOffset: false,
                forwarderOffset: false,
            });
    }, []);

    useEffect(() => {
        if (
            offerId &&
            pathname === ROUTES.UPDATE_JOB.replace(':offerId', offerId)
        ) {
            setIsJob(true);
        } else {
            setIsJob(false);
        }
    }, [pathname]);

    const watchType = watch('type');

    useEffect(() => {
        if (watchType !== '') {
            if (
                getValues('type') === 'COD' &&
                getValues('forwarderBillOfLaden') !== ''
            ) {
                setValue('govBillOfLaden', '');
                checkGBL(getValues('forwarderBillOfLaden'), 'COD');
                setGblCheck('');
            }
            if (
                getValues('type') === 'military' &&
                getValues('govBillOfLaden') !== ''
            ) {
                checkGBL(getValues('govBillOfLaden'), 'military');
                setGblCheck('');
            }
        }
    }, [watchType]);

    const watchSITstatus = watch('line_haule_data.SIT_status');

    useEffect(() => {
        if (!watchSITstatus) {
            setValue('line_haule_data.SIT', 0);
            setValue('date_in', null);
            setValue('date_out', null);
        }
    }, [watchSITstatus]);

    const watchGovBillOfLaden = watch('govBillOfLaden');

    useEffect(() => {
        const getData: any =
            watchGovBillOfLaden !== '' &&
            setTimeout(() => {
                getValues('type') === 'military' &&
                    checkGBL(watchGovBillOfLaden, 'military');
            }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [watchGovBillOfLaden]);

    const watchForwarderBillOfLaden = watch('forwarderBillOfLaden');

    useEffect(() => {
        const getData: any =
            watchForwarderBillOfLaden !== '' &&
            setTimeout(() => {
                getValues('type') === 'COD' &&
                    checkGBL(watchForwarderBillOfLaden, 'COD');
            }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [watchForwarderBillOfLaden]);

    useEffect(() => {
        if (searchObj2 && !isEqual(prevSearchObj2?.current, searchObj2)) {
            const getData: NodeJS.Timeout = setTimeout(() => {
                getAllList(searchObj2, {
                    agentIssuedOffset: false,
                    agentOffset: false,
                    forwarderOffset: false,
                });
            }, searchTimeOut);
            return () => clearTimeout(getData);
        }
    }, [searchObj2]);

    // const watchOriginPostalCode = watch('address_origin.postalCode');

    // useEffect(() => {
    //     const getData: NodeJS.Timeout = setTimeout(() => {
    //         watchOriginPostalCode && ZipLookUp('origin');
    //     }, searchTimeOut);
    //     return () => clearTimeout(getData);
    // }, [watchOriginPostalCode]);

    const watchDestOriginPostalCode = watch('address_origin.postalCode');
    const watchDestPostalCode = watch('address_destination.postalCode');
    const watchIsInternationOriginPostalCode = watch(
        'address_origin.isInternationalPostalCode',
    );
    const watchIsInternationPostalCode = watch(
        'address_destination.isInternationalPostalCode',
    );

    useEffect(() => {
        const getData: NodeJS.Timeout = setTimeout(() => {
            !watchIsInternationPostalCode &&
                watchDestPostalCode &&
                ZipLookUp('dest');
        }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [watchDestPostalCode]);
    useEffect(() => {
        const getData: NodeJS.Timeout = setTimeout(() => {
            !watchIsInternationOriginPostalCode &&
                watchDestOriginPostalCode &&
                ZipLookUp('origin');
        }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [watchDestOriginPostalCode]);

    useEffect(() => {
        reset(editData);

        if (editData) {
            setValue('forwarder_name', editData?.forwarder_data?.name ?? '');
            setValue(
                'destination_agent',
                editData?.agents_data?.agent_name ?? '',
            );
            editData?.address_origin?.isInternationalPostalCode &&
                setISInternationalOriginPostalCode(
                    editData?.address_origin?.isInternationalPostalCode ??
                        false,
                );
            editData?.address_destination?.isInternationalPostalCode &&
                setISInternationalPostalCode(
                    editData?.address_destination?.isInternationalPostalCode ??
                        false,
                );

            setValue(
                'destination_agent_nano_id',
                editData?.agents_data?.agent_nano_id ?? '',
            );
            setDestAgentData(editData?.agents_data ?? '');
            setToAgentData(editData?.to_agents_data ?? '');
            setValue('to_agent', editData?.to_agents_data?.agent_name ?? '');
            setValue(
                'to_agent_nano_id',
                editData?.to_agents_data?.agent_nano_id ?? '',
            );

            if (editData?.shipperdata?.shipper_name) {
                setValue('shipper', editData?.shipperdata?.shipper_name);
            }
            setValue('forwarderBillOfLaden', editData?.forwarderBillOfLaden);
            setShipperNanoId(editData?.shipperdata?.shipper_nano_id);

            if (
                editData &&
                editData?.shipperdata?.shipper_nano_id &&
                editData?.shipperdata?.shipper_nano_id.length > 0
            ) {
                setShippersData(editData?.shipperdata);
                setValue('shipper_info', editData?.shipperdata);
            }
        }
    }, [editData]);

    const checkGBL = async (Value: string, type: string) => {
        if (!isDisabled) {
            const { payload } = await dispatch(
                checkGBLValidate({
                    offerId: offerId ? offerId : null,
                    checkValue: Value,
                    checkType: type,
                }),
            );
            if (!payload?.status) {
                setGblCheck(payload?.message);
            } else {
                setGblCheck('');
            }
        }
    };

    const ZipLookUp = async (type: string) => {
        const { payload } = await dispatch(
            checkZipLookUp({
                zip_code:
                    type === 'origin'
                        ? getValues('address_origin.postalCode')
                        : getValues('address_destination.postalCode'),
            }),
        );
        console.log('fs', type);
        console.log('fs', payload);

        const zipData = payload?.data?.data;
        if (payload?.data?.status) {
            const zipDataState = deduplicateList(zipData?.state, 'id');
            const zipDataCity = deduplicateList(zipData?.city, 'id');
            if (
                type === 'origin'
                    ? getValues('address_origin.postalCode')
                    : getValues('address_destination.postalCode') !== ''
            ) {
                console.log('fs', type);

                if (type === 'origin') {
                    var oriStateArr: IListOption[] = [];
                    zipDataState?.map((dataOption: any) => {
                        oriStateArr.push({
                            value: dataOption?.id,
                            label: dataOption?.state_name,
                        });
                        return null;
                    });

                    var oriCityArr: IListOption[] = [];
                    let filteredObject = null;
                    let isConditionMet = false;

                    zipDataCity?.map((dataOption: any) => {
                        oriCityArr.push({
                            value: dataOption?.id,
                            label: dataOption?.city_name,
                        });

                        if (!isConditionMet) {
                            filteredObject =
                                dataOption?.id ===
                                parseInt(editData?.address_origin?.city_id);
                            isConditionMet =
                                dataOption?.id ===
                                parseInt(editData?.address_origin?.city_id);
                        }

                        return null;
                    });
                    setCityArr(oriCityArr);
                    setValue(
                        'address_origin.city',
                        filteredObject
                            ? editData?.address_origin?.city_id
                            : oriCityArr[0]?.value,
                    );
                    setValue(
                        'address_origin.city_id',
                        filteredObject
                            ? editData?.address_origin?.city_id
                            : oriCityArr[0]?.value,
                    );
                    setValue('address_origin.stateTown', oriStateArr[0]?.label);
                    setValue(
                        'address_origin.stateTown_id',
                        oriStateArr[0]?.value,
                    );
                    setValue(
                        'address_origin.countryCode',
                        zipData?.zipCheck[0]?.physical_state,
                    );
                    setZipOriError(null);
                } else {
                    const zipDataStateForElse = zipData?.state?.filter(
                        (option: any, index: number, self: any) =>
                            index ===
                            self.findIndex((o: any) => o.id === option.id),
                    );
                    var destStateArrForElse: IListOption[] = [];
                    zipDataStateForElse?.map((dataOption: any) => {
                        destStateArrForElse.push({
                            value: dataOption?.id,
                            label: dataOption?.state_name,
                        });
                        return null;
                    });

                    var destCityArrForElse: IListOption[] = [];

                    let filteredObject = null;
                    let isConditionMet = false;

                    zipDataCity?.map((dataOption: any) => {
                        destCityArrForElse.push({
                            value: dataOption?.id,
                            label: dataOption?.city_name,
                        });

                        if (!isConditionMet) {
                            filteredObject =
                                dataOption?.id ===
                                parseInt(
                                    editData?.address_destination?.city_id,
                                );
                            isConditionMet =
                                dataOption?.id ===
                                parseInt(
                                    editData?.address_destination?.city_id,
                                );
                        }

                        return null;
                    });
                    setDestCityArr(destCityArrForElse);

                    setValue(
                        'address_destination.city',
                        filteredObject
                            ? editData?.address_destination?.city_id
                            : destCityArrForElse[0]?.value,
                    );
                    setValue(
                        'address_destination.city_id',
                        filteredObject
                            ? editData?.address_destination?.city_id
                            : destCityArrForElse[0]?.value,
                    );
                    setValue(
                        'address_destination.stateTown',
                        destStateArrForElse[0]?.label,
                    );
                    setValue(
                        'address_destination.stateTown_id',
                        destStateArrForElse[0]?.value,
                    );
                    setValue(
                        'address_destination.countryCode',
                        zipData?.zipCheck[0]?.physical_state,
                    );

                    setZipDestError(null);
                }
            }
        } else {
            if (type === 'origin') {
                setZipOriError(payload?.message);
            } else {
                setZipDestError(payload?.message);
            }
        }
    };

    const changeNote = async (e: any) => {
        if (
            getValues('line_haule_data.notes') === 'none' ||
            getValues('line_haule_data.notes') === 'None'
        ) {
            setValue(
                'line_haule_data.notes',
                (
                    e.target.value.charAt(0).toUpperCase() +
                    e.target.value.slice(1)
                ).replace('None', ''),
            );
        } else {
            setValue('line_haule_data.notes', e.target.value);
        }
    };

    const onChangeSelect = async (e: any, fieldName: any) => {
        console.log(e, fieldName);

        if (fieldName === 'driver') {
            setValue(fieldName, e?.value);
        } else if (fieldName === 'destination_agent') {
            setValue('destination_agent_nano_id', e?.value);
            setValue('destination_agent', e?.label);
            setAgentChange(true);
        } else if (fieldName === 'to_agent') {
            setValue('to_agent_nano_id', e?.value);
            setValue('to_agent', e?.label);
            setAgentChange(true);
        } else {
            setValue(fieldName, e?.value);
        }
    };

    const packStartRef = useRef();
    const packUpStartRef = useRef();
    const rddStartRef = useRef();
    const submitButtonRef: any = useRef();
    const prevSearchObj2 = useRef<IListOptionSearchObj2 | null>({
        ...searchObj2,
        agentSearch: null,
    });

    const watchPackDate = watch('packDate');
    const watchPickupDate = watch('pickupDate');

    const packDate: any = useMemo(
        () => (
            <Controller
                name={`pickupDate`}
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <DateSelect
                        ref={packUpStartRef}
                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                        inputGroupClassName="mfnw__input-group"
                        placeholder="MM/DD/YYYY"
                        label="Pickup"
                        tabIndex={3}
                        selected={
                            typeof value === 'string'
                                ? moment(value).tz('America/New_York').toDate()
                                : value || null
                        }
                        dateFormat="MM/dd/yyyy"
                        inputName="pickupDate"
                        icon={faCalendar}
                        onChangeFunc={({ value: valueDateChange }: any) =>
                            onChange(valueDateChange)
                        }
                        groupInput={true}
                        disabled={isDisabled}
                    />
                )}
            />
        ),
        [watchPackDate],
    );

    const deliveryDate: any = useMemo(
        () => (
            <Controller
                name={`deliveryDate`}
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <DateSelect
                        ref={rddStartRef}
                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                        inputGroupClassName="mfnw__input-group"
                        placeholder="MM/DD/YYYY"
                        label="RDD"
                        tabIndex={4}
                        selected={
                            typeof value === 'string'
                                ? moment(value).tz('America/New_York').toDate()
                                : value || null
                        }
                        dateFormat="MM/dd/yyyy"
                        inputName="deliveryDate"
                        icon={faCalendar}
                        onChangeFunc={({ value: valueDateChange }: any) =>
                            onChange(valueDateChange)
                        }
                        groupInput={true}
                        disabled={isDisabled}
                    />
                )}
            />
        ),
        [watchPickupDate],
    );

    const HandleChange = async (data: IOfferProps) => {
        if (gblCheck === '' && zipOriError === null) {
            let payload;
            if (isChangeStatusRef.current) {
                const response = await dispatch(
                    changeOfferStatus({
                        id: offerId,
                        status: data.offerStatus,
                    }),
                );

                payload = response.payload;
            } else {
                const sendObj = {
                    ...data,
                    origin: data.address_origin,
                    destination: data.address_destination,
                    lineHaul: data.line_haule_data,
                    packDate: data?.packDate
                        ? formatDateTOCurrent(data?.packDate)
                        : null,
                    pickupDate: data?.pickupDate
                        ? formatDateTOCurrent(data?.pickupDate)
                        : null,
                    deliveryDate: data?.deliveryDate
                        ? formatDateTOCurrent(data?.deliveryDate)
                        : null,
                    driver_pickupDate: data?.driver_pickupDate
                        ? formatDateTOCurrent(data?.driver_pickupDate)
                        : null,
                    driver_deliveryDate: data?.driver_deliveryDate
                        ? formatDateTOCurrent(data?.driver_deliveryDate)
                        : null,
                    date_in: data?.date_in
                        ? formatDateTOCurrent(data?.date_in)
                        : null,
                    date_out: data?.date_out
                        ? formatDateTOCurrent(data?.date_out)
                        : null,
                    tab_type: isJobForm ? 'job' : 'offer',
                    ...(isAcceptable && { offerStatus: 'accepted' }),
                };
                const response = await dispatch(
                    offerId ? updateOffer(sendObj) : createOffer(sendObj),
                );

                payload = response.payload;
            }

            if (payload?.data?.message !== '' && payload?.data?.status) {
                if (isFormSubmitManuallyRef.current) {
                    isFormSubmitManuallyRef.current = false;
                    setShowScheduleListPopup(true);
                    getOfferData(offerId);
                } else {
                    handleClose();
                    toast.success(payload?.data?.message);
                }
                listCall();
                if (isAcceptable) {
                    navigate(routesPath?.jobs);
                }
                if (
                    editData?.offerStatus === 'accepted' &&
                    getValues('offerStatus') !== 'accepted'
                ) {
                    getValues('offerStatus') === 'pending' &&
                        navigate(routesPath?.offers);
                    getValues('offerStatus') === 'canceled' &&
                        navigate(routesPath?.dashboard);
                    getValues('offerStatus') === 'rejected' &&
                        navigate(routesPath?.dashboard);
                }

                if (isChangeStatusRef.current) {
                    isChangeStatusRef.current = false;
                }
            } else {
                toast.error(payload?.message);
            }
        }
    };

    const onClose = async () => {
        if (isDirty) {
            Swal.fire({
                title: 'Warning',
                html:
                    'Are you sure you want to close this form? <br/> ' +
                    'All changes made will be lost.',
                allowOutsideClick: false,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: cancelButtonColor,
                confirmButtonColor: confirmButtonColor,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
            }).then(async (result) => {
                if (result?.isConfirmed) {
                    handleClose();
                    reset({ ...offerFields, offerId: false });
                }
            });
        } else {
            handleClose();
            reset({ ...offerFields, offerId: false });
        }
    };

    const getAllList = async (searchObject: any, isSearch: any) => {
        if (!showScheduleListPopup) {
            const type = isJobForm
                ? { tsp: 'tsp', agent: 'agent' }
                : { tsp: 'tsp' };
            const { payload } = await dispatch(
                getAllDataList({ type: type, searchArr: searchObject }),
            );
            if (payload?.data?.message !== '' && payload?.data?.status) {
                var allDataList = payload?.data?.dataList;

                var ForwarderData: IListOption[];
                if (!isSearch?.forwarderOffset) {
                    ForwarderData = [];
                } else {
                    ForwarderData = forwarderListOptionArr ?? [];
                }
                allDataList?.ForwarderData?.map((dataOption: any) => {
                    ForwarderData.push({
                        value: dataOption.name,
                        label: dataOption.name,
                    });
                    return null;
                });
                setForwarderListOptionArr(ForwarderData);
                setForwarderListCount(
                    payload?.data?.dataList?.ForwarderDataCount,
                );
                if (!editData && !isJobForm) {
                    setValue(
                        'forwarder_name',
                        allDataList?.ForwarderData[0]?.name ?? '',
                    );
                }

                var agentsData: IListOption[];
                if (!isSearch?.agentOffset) {
                    agentsData = [];
                } else {
                    agentsData = agentListOptionArr ?? [];
                }
                allDataList?.agentsData?.map((dataOption: any) => {
                    if (dataOption?.is_active) {
                        agentsData.push({
                            value: dataOption.agent_nano_id,
                            label: dataOption?.agent_name,
                        });
                    }
                    return null;
                });
                setAgentListOptionArr(agentsData);
                setAgentListCount(payload?.data?.dataList?.agentsDataCount);
                var agentsIssuedData: IListOption[];
                if (!isSearch?.agentIssuedOffset) {
                    agentsIssuedData = [];
                } else {
                    agentsIssuedData = agentToListOptionArr ?? [];
                }
                allDataList?.agentsIssuedData?.map((dataOption: any) => {
                    if (dataOption?.is_active) {
                        agentsIssuedData.push({
                            value: dataOption.agent_nano_id,
                            label: dataOption?.agent_name,
                        });
                    }
                    return null;
                });
                setAgentToListOptionArr(agentsIssuedData);
                setAgentToListCount(
                    payload?.data?.dataList?.agentsIssuedDataCount,
                );
            } else {
                toast.error(payload?.message);
            }
        }
    };

    const addManuallyCityState = (event: any) => {
        clearErrors([
            'address_destination.city',
            'address_destination.stateTown',
            'address_destination.postalCode',
        ]);

        event.target.checked !== undefined &&
            setValue(
                'address_destination.isInternationalPostalCode',
                event.target.checked,
            );
        setValue('address_destination.postalCode', '');
        setValue('address_destination.city', '');
        setValue('address_destination.stateTown', '');
        setValue('address_destination.city_id', '');
        setValue('address_destination.stateTown_id', '');
        setZipDestError(null);
        setISInternationalPostalCode(event.target.checked ?? false);
    };
    const addManuallyCityStateOrigin = (event: any) => {
        clearErrors([
            'address_origin.city',
            'address_origin.stateTown',
            'address_origin.postalCode',
        ]);

        event.target.checked !== undefined &&
            setValue(
                'address_origin.isInternationalPostalCode',
                event.target.checked,
            );
        setValue('address_origin.postalCode', '');
        setValue('address_origin.city', '');
        setValue('address_origin.stateTown', '');
        setValue('address_origin.city_id', '');
        setValue('address_origin.stateTown_id', '');
        setZipOriError(null);
        setISInternationalOriginPostalCode(event.target.checked ?? false);
    };

    const renderOriginAddressInputs = useMemo(() => {
        if (watchIsInternationOriginPostalCode) {
            return (
                <Row className="mt-3 mt-xl-4 d-flex">
                    <Col xl={3} className="mb-3 mb-xl-0">
                        <Controller
                            name={`address_origin.postalCode`}
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <InputGroupText
                                        onChangeFunc={onChange}
                                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                                        value={value}
                                        tabIndex={10}
                                        id={`address_origin.postalCode`}
                                        inputType={'text'}
                                        placeholder={'Postal Code'}
                                        disabled={isDisabled}
                                        groupInput={false}
                                        errorText={
                                            errors?.address_origin?.postalCode
                                                ?.message ?? zipOriError
                                        }
                                    />
                                </>
                            )}
                        />
                    </Col>
                    <Col md={12} xl={6} className="mb-3 mb-xl-0">
                        <Controller
                            name={`address_origin.city`}
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <InputGroupText
                                    onChangeFunc={onChange}
                                    className="mfnw__login-form-control mfnw__login-form-control-bg"
                                    value={value}
                                    tabIndex={11}
                                    id={`address_origin.city`}
                                    inputType={'text'}
                                    placeholder={'City'}
                                    disabled={isDisabled}
                                    groupInput={false}
                                    errorText={
                                        errors?.address_origin?.city?.message
                                    }
                                />
                            )}
                        />
                    </Col>
                    <Col md={12} xl={3}>
                        <Controller
                            name={`address_origin.stateTown`}
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <InputGroupText
                                        onChangeFunc={onChange}
                                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                                        value={value}
                                        tabIndex={11}
                                        id={`address_origin.stateTown`}
                                        inputType={'text'}
                                        placeholder={'State'}
                                        disabled={isDisabled}
                                        groupInput={false}
                                        errorText={
                                            errors?.address_origin?.stateTown
                                                ?.message
                                        }
                                    />
                                </>
                            )}
                        />
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row className="mt-3 mt-xl-4 d-flex">
                    <Col xl={3} className="mb-3 mb-xl-0">
                        <Controller
                            name={`address_origin.postalCode`}
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <InputGroupText
                                        onChangeFunc={onChange}
                                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                                        value={value}
                                        tabIndex={10}
                                        id={`address_origin.postalCode`}
                                        inputType={'text'}
                                        placeholder={'Postal Code'}
                                        disabled={isDisabled}
                                        groupInput={false}
                                        errorText={
                                            errors?.address_origin?.postalCode
                                                ?.message ?? zipOriError
                                        }
                                    />
                                </>
                            )}
                        />
                    </Col>
                    <Col md={12} xl={6} className="mb-3 mb-xl-0">
                        <Controller
                            name={`address_origin.city`}
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <ReactSelectGroup
                                    value={cityArr.find(
                                        (c: any) =>
                                            c?.value === parseInt(value),
                                    )}
                                    labelShow={false}
                                    inputClassName="mfnw__input-group"
                                    options={cityArr}
                                    placeholder={'City'}
                                    onInputChange={(valueChange: any) => {
                                        if (valueChange !== '') {
                                            var set: any = {
                                                oriCity: '',
                                                oriState: '',
                                                destCity: valueChange,
                                                destState: '',
                                            };
                                            setSearchObj(set);
                                        }
                                    }}
                                    onChangeFunc={(
                                        valueStateTownChange: any,
                                    ) => {
                                        setValue(
                                            'address_origin.city',
                                            valueStateTownChange?.label,
                                        );

                                        setValue(
                                            'address_origin.city_id',
                                            valueStateTownChange?.value,
                                        );
                                    }}
                                    tabIndex={2}
                                    className="w-100"
                                    isDisabled={
                                        isDisabled ||
                                        (!zipOriError &&
                                            getValues(
                                                'address_origin.postalCode',
                                            ) === null) ||
                                        (cityArr.length === 1 ? true : false)
                                    }
                                    isSearchable={false}
                                    errorText={
                                        errors?.address_origin?.city?.message
                                    }
                                />
                            )}
                        />
                    </Col>
                    <Col md={12} xl={3} className="mb-3 mb-xl-0">
                        <Controller
                            name={`address_origin.stateTown`}
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <InputGroupText
                                        onChangeFunc={onChange}
                                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                                        value={value}
                                        tabIndex={1}
                                        id={`address_origin.stateTown`}
                                        inputType={'text'}
                                        placeholder={'State'}
                                        disabled={true}
                                        groupInput={false}
                                    />
                                </>
                            )}
                        />
                    </Col>
                </Row>
            );
        }
    }, [
        watchIsInternationOriginPostalCode,
        zipOriError,
        cityArr,
        isDisabled,
        errors,
    ]);
    const renderAddressInputs = useMemo(() => {
        if (watchIsInternationPostalCode) {
            return (
                <Row className="mt-3 mt-xl-4 d-flex">
                    <Col xl={3} className="mb-3 mb-xl-0">
                        <Controller
                            name={`address_destination.postalCode`}
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <InputGroupText
                                        onChangeFunc={onChange}
                                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                                        value={value}
                                        tabIndex={10}
                                        id={`address_destination.postalCode`}
                                        inputType={'text'}
                                        placeholder={'Postal Code'}
                                        disabled={isDisabled}
                                        groupInput={false}
                                        errorText={
                                            errors?.address_destination
                                                ?.postalCode?.message ??
                                            zipDestError
                                        }
                                    />
                                </>
                            )}
                        />
                    </Col>
                    <Col md={12} xl={6} className="mb-3 mb-xl-0">
                        <Controller
                            name={`address_destination.city`}
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <InputGroupText
                                    onChangeFunc={onChange}
                                    className="mfnw__login-form-control mfnw__login-form-control-bg"
                                    value={value}
                                    tabIndex={11}
                                    id={`address_destination.city`}
                                    inputType={'text'}
                                    placeholder={'City'}
                                    disabled={isDisabled}
                                    groupInput={false}
                                    errorText={
                                        errors?.address_destination?.city
                                            ?.message
                                    }
                                />
                            )}
                        />
                    </Col>
                    <Col md={12} xl={3}>
                        <Controller
                            name={`address_destination.stateTown`}
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <InputGroupText
                                        onChangeFunc={onChange}
                                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                                        value={value}
                                        tabIndex={11}
                                        id={`address_destination.stateTown`}
                                        inputType={'text'}
                                        placeholder={'State'}
                                        disabled={isDisabled}
                                        groupInput={false}
                                        errorText={
                                            errors?.address_destination
                                                ?.stateTown?.message
                                        }
                                    />
                                </>
                            )}
                        />
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row className="mt-3 mt-xl-4 d-flex">
                    <Col xl={3} className="mb-3 mb-xl-0">
                        <Controller
                            name={`address_destination.postalCode`}
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <InputGroupText
                                        onChangeFunc={onChange}
                                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                                        value={value}
                                        tabIndex={10}
                                        id={`address_destination.postalCode`}
                                        inputType={'text'}
                                        placeholder={'Postal Code'}
                                        disabled={isDisabled}
                                        groupInput={false}
                                        errorText={
                                            errors?.address_destination
                                                ?.postalCode?.message ??
                                            zipDestError
                                        }
                                    />
                                </>
                            )}
                        />
                    </Col>
                    <Col md={12} xl={6} className="mb-3 mb-xl-0">
                        <Controller
                            name={`address_destination.city`}
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <ReactSelectGroup
                                    value={destCityArr.find(
                                        (c: any) =>
                                            c?.value === parseInt(value),
                                    )}
                                    labelShow={false}
                                    inputClassName="mfnw__input-group"
                                    options={destCityArr}
                                    placeholder={'City'}
                                    onInputChange={(valueChange: any) => {
                                        if (valueChange !== '') {
                                            var set: any = {
                                                oriCity: '',
                                                oriState: '',
                                                destCity: valueChange,
                                                destState: '',
                                            };
                                            setSearchObj(set);
                                        }
                                    }}
                                    onChangeFunc={(
                                        valueStateTownChange: any,
                                    ) => {
                                        setValue(
                                            'address_destination.city',
                                            valueStateTownChange?.label,
                                        );

                                        setValue(
                                            'address_destination.city_id',
                                            valueStateTownChange?.value,
                                        );
                                    }}
                                    tabIndex={2}
                                    className="w-100"
                                    isDisabled={
                                        isDisabled ||
                                        (!zipDestError &&
                                            getValues(
                                                'address_destination.postalCode',
                                            ) === null) ||
                                        (destCityArr.length === 1
                                            ? true
                                            : false)
                                    }
                                    isSearchable={false}
                                    errorText={
                                        errors?.address_destination?.city
                                            ?.message
                                    }
                                />
                            )}
                        />
                    </Col>
                    <Col md={12} xl={3} className="mb-3 mb-xl-0">
                        <Controller
                            name={`address_destination.stateTown`}
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <InputGroupText
                                        onChangeFunc={onChange}
                                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                                        value={value}
                                        tabIndex={1}
                                        id={`address_destination.stateTown`}
                                        inputType={'text'}
                                        placeholder={'State'}
                                        disabled={true}
                                        groupInput={false}
                                    />
                                </>
                            )}
                        />
                    </Col>
                </Row>
            );
        }
    }, [
        watchIsInternationPostalCode,
        zipDestError,
        destCityArr,
        isDisabled,
        errors,
    ]);

    const preventEnterKeySubmission = (
        e: React.KeyboardEvent<HTMLFormElement>,
    ) => {
        const target = e.target;
        if (e.key === 'Enter' && target instanceof HTMLInputElement) {
            e.preventDefault();
            submitButtonRef?.current?.click();
        }
    };
    const renderDestAgentTooltip = (props: any) =>
        destAgentData ? (
            <Tooltip
                id="agent-details-tooltip"
                className="custom-tooltip"
                {...props}
            >
                <div className="text-start">
                    <p className="mb-0">
                        <strong>Address:</strong> {destAgentData?.agent_address}
                        , {destAgentData?.agent_city_id},{' '}
                        {destAgentData?.agent_state}
                    </p>
                    <p className="mb-0">
                        <strong>Phone:</strong> {destAgentData?.agent_phone}
                    </p>
                    <p className="mb-0">
                        <strong>Email:</strong> {destAgentData?.agent_email}
                    </p>
                    {/* Add more fields as needed */}
                </div>
            </Tooltip>
        ) : (
            <></>
        );
    const renderOriginAgentTooltip = (props: any) =>
        toAgentData ? (
            <Tooltip
                id="agent-details-tooltip"
                className="custom-tooltip"
                {...props}
            >
                <div className="text-start">
                    <p className="mb-0">
                        <strong>Address:</strong> {toAgentData?.agent_address},{' '}
                        {toAgentData?.agent_city_id}, {toAgentData?.agent_state}
                    </p>
                    <p className="mb-0">
                        <strong>Phone:</strong> {toAgentData?.agent_phone}
                    </p>
                    <p className="mb-0">
                        <strong>Email:</strong> {toAgentData?.agent_email}
                    </p>
                    {/* Add more fields as needed */}
                </div>
            </Tooltip>
        ) : (
            <></>
        );
    return (
        <>
            <Modal
                show={showPopup}
                onHide={onClose}
                backdrop="static"
                backdropClassName="modal-backdrop-upper"
                centered
                size="xl"
                className="offer_add_modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5 fw-semibold">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="main-bodysection py-4">
                    {isOfferData ? (
                        <LoaderBtn />
                    ) : (
                        <Form
                            onSubmit={handleSubmit(HandleChange)}
                            onKeyDown={preventEnterKeySubmission}
                        >
                            {isJobForm && (
                                <div className="mfnw__dashboard-content mfnw_spacing d-flex table-responsive-lg pt-3">
                                    <Tabs
                                        defaultActiveKey={selectedTab}
                                        className="mfnw__dashboard_linetab mb-3 me-4"
                                        onSelect={(e: any) => {
                                            e === 'document03' &&
                                                handleViewListShow();
                                            e === 'document03' &&
                                                setSelectedTab('document03');
                                            e === 'note04' &&
                                                handleNoteListShow();
                                            e === 'note04' &&
                                                setSelectedTab('note04');

                                            e === 'history05' &&
                                                handleHistoryListShow();
                                            e === 'history05' &&
                                                setSelectedTab('history05');

                                            e === 'scheduled02' &&
                                                handleScheduleListShow();
                                            e === 'scheduled02' &&
                                                setSelectedTab('scheduled02');
                                        }}
                                    >
                                        <Tab
                                            eventKey="tsp01"
                                            title="INFO"
                                            className="mt-2"
                                        ></Tab>
                                    </Tabs>
                                    {editData && (
                                        <div className="d-flex">
                                            <div
                                                className="tab-div mx-4 editData cursor-pointer"
                                                onClick={() => {
                                                    handleScheduleListShow();
                                                    setSelectedTab(
                                                        'scheduled02',
                                                    );
                                                }}
                                            >
                                                SCHEDULE
                                            </div>
                                            <div
                                                className="tab-div mx-4 position-relative cursor-pointer"
                                                onClick={() => {
                                                    handleViewListShow();
                                                    setSelectedTab(
                                                        'document03',
                                                    );
                                                }}
                                            >
                                                DOCUMENTS
                                                {totalDocumentDataCount !==
                                                0 ? (
                                                    <Badge bg="primary rounded-circle me-1 position-absolute bottom-50 text-sm mb-2">
                                                        {totalDocumentDataCount}
                                                    </Badge>
                                                ) : editData?.offer_documents
                                                      .length &&
                                                  editData?.offer_documents
                                                      .length !== 0 ? (
                                                    <Badge bg="primary rounded-circle me-1 position-absolute bottom-50 text-sm mb-2">
                                                        {
                                                            editData
                                                                ?.offer_documents
                                                                .length
                                                        }
                                                    </Badge>
                                                ) : null}
                                            </div>
                                            <div
                                                className="tab-div mx-4 position-relative cursor-pointer"
                                                onClick={() => {
                                                    handleNoteListShow();
                                                    setSelectedTab('note04');
                                                }}
                                            >
                                                NOTES
                                                {totalNoteDataCount !== 0 ? (
                                                    <Badge bg="primary rounded-circle me-1 position-absolute bottom-50 text-sm mb-2">
                                                        {totalNoteDataCount}
                                                    </Badge>
                                                ) : editData?.offer_notes
                                                      .length &&
                                                  editData?.offer_notes
                                                      .length !== 0 ? (
                                                    <Badge bg="primary rounded-circle me-1 position-absolute bottom-50 text-sm mb-2">
                                                        {
                                                            editData
                                                                ?.offer_notes
                                                                .length
                                                        }
                                                    </Badge>
                                                ) : null}
                                            </div>
                                            <div
                                                className="tab-div mx-4 cursor-pointer"
                                                onClick={() => {
                                                    handleHistoryListShow();
                                                    setSelectedTab('history05');
                                                }}
                                            >
                                                HISTORY
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {isJobForm &&
                                (isAcceptable ? !isDisabled : isDisabled) && (
                                    <Row className="my-4">
                                        <Col md={6}></Col>
                                        <Col md={6}>
                                            <div className="d-flex justify-content-end me-1">
                                                <FontAwesomeIcon
                                                    className="mx-2 cursor-pointer text-iconcolor"
                                                    icon={faPen}
                                                    onClick={() => {
                                                        handleEditShow(offerId);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            <Row>
                                <Col xl={6}>
                                    <div className="mfnw_add_job_popup pt-3 align-items-center d-flex flex-wrap text-bold">
                                        <p className="text-dark align-items-center text-sm me-3 me-xl-0 mb-0 fw-bold mb-3 mb-xl-0">
                                            OFFER TYPE
                                        </p>
                                        <div className="d-flex mfnw-box-one">
                                            <div className="offer_popup_button_responsive">
                                                <Controller
                                                    name="type"
                                                    control={control}
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                        },
                                                    }: any) => (
                                                        <RadioButton
                                                            onChangeFunc={
                                                                onChange
                                                            }
                                                            id="inline-radio-military"
                                                            name="type"
                                                            value="military"
                                                            checked={
                                                                value ===
                                                                'military'
                                                            }
                                                            label="MILITARY"
                                                            disabled={
                                                                isDisabled
                                                            }
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="offer_popup_button_responsive">
                                                <Controller
                                                    name="type"
                                                    control={control}
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                        },
                                                    }: any) => (
                                                        <RadioButton
                                                            onChangeFunc={
                                                                onChange
                                                            }
                                                            id="inline-radio-cod"
                                                            name="type"
                                                            classNameParentDiv="m-0"
                                                            value="COD"
                                                            checked={
                                                                value === 'COD'
                                                            }
                                                            label="COD"
                                                            disabled={
                                                                isDisabled
                                                            }
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12} xl={6} className="pt-3 pt-xl-0">
                                    {isJobForm ? (
                                        <Row>
                                            <Col md={6} lg={5}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{
                                                        show: 250,
                                                        hide: 400,
                                                    }}
                                                    overlay={
                                                        renderDestAgentTooltip
                                                    }
                                                >
                                                    <div className="mt-2">
                                                        <p className="mb-0 text-sm text-light fw-semibold">
                                                            DESTINATION AGENT
                                                        </p>

                                                        <Controller
                                                            name={`destination_agent`}
                                                            control={control}
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    value,
                                                                },
                                                            }: any) => (
                                                                <>
                                                                    <ReactSelectGroup
                                                                        autoFocus
                                                                        value={
                                                                            getValues(
                                                                                'destination_agent_nano_id',
                                                                            ) && {
                                                                                value: getValues(
                                                                                    'destination_agent_nano_id',
                                                                                ),
                                                                                label: getValues(
                                                                                    'destination_agent',
                                                                                ),
                                                                            }
                                                                        }
                                                                        onChange={(
                                                                            e: any,
                                                                        ) =>
                                                                            onChangeSelect(
                                                                                e,
                                                                                'destination_agent',
                                                                            )
                                                                        }
                                                                        options={
                                                                            agentListOptionArr
                                                                        }
                                                                        tabIndex={
                                                                            1
                                                                        }
                                                                        onInputChange={(
                                                                            valueChange: any,
                                                                        ) => {
                                                                            if (
                                                                                valueChange !==
                                                                                ''
                                                                            ) {
                                                                                var set: any =
                                                                                    {
                                                                                        ...searchObj2,
                                                                                        agentSearch:
                                                                                            valueChange,
                                                                                    };
                                                                                setSearchObj2(
                                                                                    set,
                                                                                );
                                                                                prevSearchObj2.current =
                                                                                    searchObj2;
                                                                            } else {
                                                                                prevSearchObj2.current =
                                                                                    searchObj2;
                                                                                setSearchObj2(
                                                                                    {
                                                                                        ...searchObj2,
                                                                                        agentSearch:
                                                                                            '',
                                                                                    },
                                                                                );
                                                                            }
                                                                        }}
                                                                        classNames={{
                                                                            control:
                                                                                (
                                                                                    state: any,
                                                                                ) =>
                                                                                    state.isFocused
                                                                                        ? 'border-green-600'
                                                                                        : '',
                                                                        }}
                                                                        isSearchable={
                                                                            true
                                                                        }
                                                                        isDisabled={
                                                                            isDisabled
                                                                        }
                                                                        placeholder="Agent Name.."
                                                                        onMenuScrollToBottom={() => {
                                                                            if (
                                                                                agentListOptionArr &&
                                                                                !(
                                                                                    agentListOptionArr?.length >=
                                                                                    agentListCount
                                                                                )
                                                                            ) {
                                                                                const details =
                                                                                    {
                                                                                        agentIssuedOffset:
                                                                                            false,
                                                                                        agentOffset:
                                                                                            true,
                                                                                        forwarderOffset:
                                                                                            false,
                                                                                    };
                                                                                getAllList(
                                                                                    {
                                                                                        ...searchObj2,
                                                                                        agentOffset:
                                                                                            agentListOptionArr?.length,
                                                                                    },
                                                                                    details,
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                    {errors
                                                                        ?.destination_agent
                                                                        ?.message && (
                                                                        <ErrorMsg
                                                                            errorText={
                                                                                errors
                                                                                    ?.destination_agent
                                                                                    ?.message
                                                                            }
                                                                        ></ErrorMsg>
                                                                    )}
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </OverlayTrigger>
                                            </Col>
                                            <Col md={6} lg={5}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{
                                                        show: 250,
                                                        hide: 400,
                                                    }}
                                                    overlay={
                                                        renderOriginAgentTooltip
                                                    }
                                                >
                                                    <div className="mt-2">
                                                        <p className="mb-0 text-sm text-light fw-semibold pt-1 pt-xl-0">
                                                            ISSUED TO
                                                        </p>
                                                        <Controller
                                                            name={`to_agent`}
                                                            control={control}
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    value,
                                                                },
                                                            }: any) => (
                                                                <>
                                                                    <ReactSelectGroup
                                                                        value={
                                                                            getValues(
                                                                                'to_agent_nano_id',
                                                                            ) && {
                                                                                value: getValues(
                                                                                    'to_agent_nano_id',
                                                                                ),
                                                                                label: getValues(
                                                                                    'to_agent',
                                                                                ),
                                                                            }
                                                                        }
                                                                        onChange={(
                                                                            e: any,
                                                                        ) =>
                                                                            onChangeSelect(
                                                                                e,
                                                                                'to_agent',
                                                                            )
                                                                        }
                                                                        options={
                                                                            agentToListOptionArr
                                                                        }
                                                                        onInputChange={(
                                                                            valueChange: any,
                                                                        ) => {
                                                                            if (
                                                                                valueChange !==
                                                                                ''
                                                                            ) {
                                                                                var set: any =
                                                                                    {
                                                                                        ...searchObj2,
                                                                                        agentIssuedSearch:
                                                                                            valueChange,
                                                                                    };
                                                                                setSearchObj2(
                                                                                    set,
                                                                                );
                                                                                prevSearchObj2.current =
                                                                                    searchObj2;
                                                                            } else {
                                                                                prevSearchObj2.current =
                                                                                    searchObj2;
                                                                                setSearchObj2(
                                                                                    {
                                                                                        ...searchObj2,
                                                                                        agentIssuedSearch:
                                                                                            '',
                                                                                    },
                                                                                );
                                                                            }
                                                                        }}
                                                                        tabIndex={
                                                                            1
                                                                        }
                                                                        classNames={{
                                                                            control:
                                                                                (
                                                                                    state: any,
                                                                                ) =>
                                                                                    state.isFocused
                                                                                        ? 'border-green-600'
                                                                                        : '',
                                                                        }}
                                                                        isSearchable={
                                                                            true
                                                                        }
                                                                        isDisabled={
                                                                            isDisabled
                                                                        }
                                                                        placeholder="Issued Name.."
                                                                        onMenuScrollToBottom={() => {
                                                                            if (
                                                                                agentToListOptionArr &&
                                                                                !(
                                                                                    agentToListOptionArr?.length >=
                                                                                    agentToListCount
                                                                                )
                                                                            ) {
                                                                                getAllList(
                                                                                    {
                                                                                        ...searchObj2,
                                                                                        agentIssuedOffset:
                                                                                            agentToListOptionArr?.length,
                                                                                    },
                                                                                    {
                                                                                        agentIssuedOffset:
                                                                                            true,
                                                                                        agentOffset:
                                                                                            false,
                                                                                        forwarderOffset:
                                                                                            false,
                                                                                    },
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                    {errors
                                                                        ?.to_agent
                                                                        ?.message && (
                                                                        <ErrorMsg
                                                                            errorText={
                                                                                errors
                                                                                    ?.to_agent
                                                                                    ?.message
                                                                            }
                                                                        ></ErrorMsg>
                                                                    )}
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </OverlayTrigger>
                                            </Col>
                                            <Col
                                                xs={1}
                                                md={1}
                                                lg={1}
                                                className="d-flex pt-3 align-items-center"
                                            >
                                                {!isDisabled && (
                                                    <>
                                                        <FontAwesomeIcon
                                                            role="button"
                                                            onClick={
                                                                handleAgentShow
                                                            }
                                                            icon={faCirclePlus}
                                                            className="icon"
                                                        />
                                                        <p
                                                            role="button"
                                                            className="text-primary text-base fw-bold m-auto ps-2"
                                                            onClick={() => {
                                                                !isDisabled &&
                                                                    handleAgentShow();
                                                            }}
                                                        >
                                                            Agent
                                                        </p>
                                                    </>
                                                )}
                                            </Col>
                                        </Row>
                                    ) : (
                                        <>
                                            {(isAcceptable
                                                ? !isDisabled
                                                : isDisabled) && (
                                                <div className="d-flex justify-content-end me-1">
                                                    <FontAwesomeIcon
                                                        className="mx-2 cursor-pointer text-iconcolor"
                                                        icon={faPen}
                                                        onClick={() => {
                                                            handleEditShow(
                                                                offerId,
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}
                                </Col>
                            </Row>
                            <p className="text-dark text-sm fw-semibold mt-3 mt-xl-4">
                                OFFER INFORMATION
                            </p>
                            <Row>
                                <Col xl={7}>
                                    <Row>
                                        <Col lg={10}>
                                            <div className="mb-3 mb-lg-3">
                                                <Controller
                                                    name={'shipper'}
                                                    control={control}
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                        },
                                                    }: any) => (
                                                        <InputGroupText
                                                            onChangeFunc={
                                                                onChange
                                                            }
                                                            value={value}
                                                            className="mfnw__offer_form-control"
                                                            id="shipper"
                                                            inputName="shipper"
                                                            label="Shipper"
                                                            tabIndex={1}
                                                            inputType="text"
                                                            inputGroupClassName="mfnw__input-group"
                                                            placeholder="Shipper"
                                                            disabled={
                                                                isDisabled
                                                            }
                                                            autoFocus={
                                                                !isJobForm
                                                            }
                                                            errorText={
                                                                errors.shipper
                                                                    ?.message
                                                            }
                                                        />
                                                    )}
                                                />

                                                {!errors.shipper?.message &&
                                                    isShipperSet &&
                                                    getValues('shipper') ===
                                                        '' && (
                                                        <ErrorMsg
                                                            errorText={
                                                                'Shipper field is required'
                                                            }
                                                        ></ErrorMsg>
                                                    )}
                                            </div>
                                        </Col>
                                        <Col
                                            lg={2}
                                            className="mfnw_moreinfo_frist"
                                        >
                                            <div
                                                className="d-flex mt-1 mt-xl-3 mfmw_offer_info"
                                                onClick={() => {
                                                    handleShowShipper();
                                                }}
                                            >
                                                {!isDisabled && (
                                                    <>
                                                        <FontAwesomeIcon
                                                            role="button"
                                                            icon={faCirclePlus}
                                                            className="icon text-center"
                                                        />
                                                    </>
                                                )}
                                                <p
                                                    className="text-primary text-base fw-bold ps-2"
                                                    role="button"
                                                >
                                                    More Info
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={10}>
                                            <div className="pt-lg-2 mfnw_shipper_component">
                                                <div className="pb-0 pb-lg-2">
                                                    <Controller
                                                        name="forwarder_name"
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                value,
                                                            },
                                                        }: any) => (
                                                            <>
                                                                <ReactSelectGroup
                                                                    value={
                                                                        getValues(
                                                                            'forwarder_name',
                                                                        ) && {
                                                                            value: getValues(
                                                                                'forwarder_name',
                                                                            ),
                                                                            label: getValues(
                                                                                'forwarder_name',
                                                                            ),
                                                                        }
                                                                    }
                                                                    name={
                                                                        'forwarder_name'
                                                                    }
                                                                    inputClassName="mfnw__input-group"
                                                                    options={
                                                                        forwarderListOptionArr
                                                                    }
                                                                    onInputChange={(
                                                                        valueChange: any,
                                                                    ) => {
                                                                        if (
                                                                            valueChange !==
                                                                            ''
                                                                        ) {
                                                                            var set: any =
                                                                                {
                                                                                    ...searchObj2,
                                                                                    forwarderSearch:
                                                                                        valueChange,
                                                                                };
                                                                            setSearchObj2(
                                                                                set,
                                                                            );
                                                                            prevSearchObj2.current =
                                                                                searchObj2;
                                                                        } else {
                                                                            prevSearchObj2.current =
                                                                                searchObj2;
                                                                            setSearchObj2(
                                                                                {
                                                                                    ...searchObj2,
                                                                                    forwarderSearch:
                                                                                        '',
                                                                                },
                                                                            );
                                                                        }
                                                                    }}
                                                                    labelShow={
                                                                        false
                                                                    }
                                                                    tabIndex={1}
                                                                    onChangeFunc={(
                                                                        stateValue: any,
                                                                    ) => {
                                                                        onChangeSelect(
                                                                            stateValue,
                                                                            'forwarder_name',
                                                                        );
                                                                    }}
                                                                    label={''}
                                                                    isSearchable={
                                                                        true
                                                                    }
                                                                    isDisabled={
                                                                        isDisabled
                                                                    }
                                                                    errorText={
                                                                        errors
                                                                            ?.forwarder_name
                                                                            ?.message
                                                                    }
                                                                    placeholder="TSP Name"
                                                                    onMenuScrollToBottom={() => {
                                                                        if (
                                                                            agentToListOptionArr &&
                                                                            !(
                                                                                forwarderListOptionArr?.length >=
                                                                                agentToListCount
                                                                            )
                                                                        ) {
                                                                            getAllList(
                                                                                {
                                                                                    ...searchObj2,
                                                                                    forwarderOffset:
                                                                                        forwarderListOptionArr?.length,
                                                                                },
                                                                                {
                                                                                    agentIssuedOffset:
                                                                                        true,
                                                                                    agentOffset:
                                                                                        false,
                                                                                    forwarderOffset:
                                                                                        true,
                                                                                },
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col
                                            lg={2}
                                            className="mfnw_moreinfo_frist"
                                        >
                                            <div
                                                className="d-flex mt-3 mt-xl-3 mfmw_offer_info"
                                                onClick={() => {
                                                    handleAddTSPShow();
                                                }}
                                            >
                                                {!isDisabled && (
                                                    <>
                                                        <FontAwesomeIcon
                                                            role="button"
                                                            icon={faCirclePlus}
                                                            className="icon text-center"
                                                        />
                                                    </>
                                                )}
                                                <p
                                                    className="text-primary text-base fw-bold ps-2"
                                                    role="button"
                                                >
                                                    Add TSP
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl={5}>
                                    <Row>
                                        <Col lg={6} className="pt-1 pt-xl-0">
                                            <div className="mb-3 mb-lg-4">
                                                <Controller
                                                    name={`govBillOfLaden`}
                                                    control={control}
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                        },
                                                    }: any) => (
                                                        <>
                                                            <InputGroupText
                                                                onChangeFunc={
                                                                    onChange
                                                                }
                                                                value={value}
                                                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                                tabIndex={1}
                                                                id={`govBillOfLaden`}
                                                                inputType={
                                                                    'text'
                                                                }
                                                                label="GBL #"
                                                                groupInput={
                                                                    true
                                                                }
                                                                disabled={
                                                                    (isJob &&
                                                                        authUser?.user_type ===
                                                                            'user') ||
                                                                    getValues(
                                                                        'type',
                                                                    ) ===
                                                                        'COD' ||
                                                                    isDisabled
                                                                }
                                                                placeholder="GBL #"
                                                                errorText={
                                                                    errors
                                                                        .govBillOfLaden
                                                                        ?.message
                                                                }
                                                            />
                                                            {errors
                                                                .govBillOfLaden
                                                                ?.message
                                                                ? null
                                                                : gblCheck &&
                                                                  getValues(
                                                                      'type',
                                                                  ) ===
                                                                      'military' && (
                                                                      <div className="invalid-msg">
                                                                          {
                                                                              gblCheck
                                                                          }
                                                                      </div>
                                                                  )}
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="mb-3 mb-lg-0">
                                                <Controller
                                                    name={`forwarderBillOfLaden`}
                                                    control={control}
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                        },
                                                    }: any) => (
                                                        <>
                                                            <InputGroupText
                                                                onChangeFunc={
                                                                    onChange
                                                                }
                                                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                                value={value}
                                                                tabIndex={1}
                                                                id={`forwarderBillOfLaden`}
                                                                inputType={
                                                                    'text'
                                                                }
                                                                inputName="forwarderBillOfLaden"
                                                                label="BOL"
                                                                placeholder={
                                                                    'BOL '
                                                                }
                                                                disabled={
                                                                    isDisabled
                                                                }
                                                                groupInput={
                                                                    true
                                                                }
                                                                errorText={
                                                                    errors
                                                                        .forwarderBillOfLaden
                                                                        ?.message
                                                                }
                                                            />
                                                            {errors
                                                                .forwarderBillOfLaden
                                                                ?.message
                                                                ? null
                                                                : gblCheck &&
                                                                  getValues(
                                                                      'type',
                                                                  ) ===
                                                                      'COD' && (
                                                                      <div className="invalid-msg">
                                                                          {
                                                                              gblCheck
                                                                          }
                                                                      </div>
                                                                  )}
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6} className="pt-1 pt-xl-0">
                                            <div className="mb-3 mb-lg-4">
                                                <Controller
                                                    name={`packDate`}
                                                    control={control}
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                        },
                                                    }: any) => (
                                                        <DateSelect
                                                            ref={packStartRef}
                                                            className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                            inputGroupClassName="mfnw__input-group"
                                                            placeholder="MM/DD/YYYY"
                                                            label="Pack"
                                                            tabIndex={2}
                                                            selected={
                                                                typeof value ===
                                                                'string'
                                                                    ? moment(
                                                                          value,
                                                                      )
                                                                          .tz(
                                                                              'America/New_York',
                                                                          )
                                                                          .toDate()
                                                                    : value ||
                                                                      null
                                                            }
                                                            dateFormat="MM/dd/yyyy"
                                                            inputName="packDate"
                                                            icon={faCalendar}
                                                            onChangeFunc={({
                                                                value: valueDateChange,
                                                            }: any) =>
                                                                onChange(
                                                                    valueDateChange,
                                                                )
                                                            }
                                                            groupInput={true}
                                                            disabled={
                                                                isDisabled
                                                            }
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="mb-3 mb-lg-4">
                                                {packDate}
                                                <ErrorMsg
                                                    errorText={
                                                        errors?.pickupDate
                                                            ?.message
                                                    }
                                                />
                                            </div>
                                            <div className="mb-3 mb-lg-4">
                                                {deliveryDate}
                                                <ErrorMsg
                                                    errorText={
                                                        errors?.deliveryDate
                                                            ?.message
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            {/* <Row className="title-icon mt-lg-4 justify-content-end">
                                <Col xl={5}>
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3 mb-lg-4">
                                                <Controller
                                                    name={`date_in`}
                                                    control={control}
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                        },
                                                    }: any) => (
                                                        <DateSelect
                                                            ref={checkInRef}
                                                            className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                            inputGroupClassName="mfnw__input-group"
                                                            placeholder="MM/DD/YYYY"
                                                            label="Date In"
                                                            tabIndex={2}
                                                            selected={
                                                                typeof value ===
                                                                'string'
                                                                    ? moment(
                                                                          value,
                                                                      )
                                                                          .tz(
                                                                              'America/New_York',
                                                                          )
                                                                          .toDate()
                                                                    : value ||
                                                                      null
                                                            }
                                                            dateFormat="MM/dd/yyyy"
                                                            inputName="date_in"
                                                            icon={faCalendar}
                                                            onChangeFunc={({
                                                                value: valueDateChange,
                                                            }: any) =>
                                                                onChange(
                                                                    valueDateChange,
                                                                )
                                                            }
                                                            groupInput={true}
                                                            disabled={
                                                                isDisabled
                                                                    ? watchSITstatus ===
                                                                          0 ||
                                                                      watchSITstatus.toString() ===
                                                                          '0'
                                                                        ? isDisabled
                                                                        : !isAcceptable
                                                                    : isAcceptable ||
                                                                      watchSITstatus ===
                                                                          0 ||
                                                                      watchSITstatus.toString() ===
                                                                          '0'
                                                            }
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <ErrorMsg
                                                errorText={
                                                    errors?.date_in?.message
                                                }
                                            />
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3 mb-lg-4">
                                                <Controller
                                                    name={`date_out`}
                                                    control={control}
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                        },
                                                    }: any) => (
                                                        <DateSelect
                                                            ref={checkOutRef}
                                                            className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                            inputGroupClassName="mfnw__input-group"
                                                            placeholder="MM/DD/YYYY"
                                                            label="Date Out"
                                                            tabIndex={2}
                                                            selected={
                                                                typeof value ===
                                                                'string'
                                                                    ? moment(
                                                                          value,
                                                                      )
                                                                          .tz(
                                                                              'America/New_York',
                                                                          )
                                                                          .toDate()
                                                                    : value ||
                                                                      null
                                                            }
                                                            dateFormat="MM/dd/yyyy"
                                                            inputName="date_out"
                                                            icon={faCalendar}
                                                            onChangeFunc={({
                                                                value: valueDateChange,
                                                            }: any) =>
                                                                onChange(
                                                                    valueDateChange,
                                                                )
                                                            }
                                                            groupInput={true}
                                                            disabled={
                                                                isDisabled
                                                                    ? watchSITstatus ===
                                                                          0 ||
                                                                      watchSITstatus.toString() ===
                                                                          '0'
                                                                        ? isDisabled
                                                                        : !isAcceptable
                                                                    : isAcceptable ||
                                                                      watchSITstatus ===
                                                                          0 ||
                                                                      watchSITstatus.toString() ===
                                                                          '0'
                                                            }
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <ErrorMsg
                                                errorText={
                                                    errors?.date_out?.message
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row> */}
                            {/* nex section */}
                            <Row className="title-icon mt-lg-4">
                                <Col md={6}>
                                    <div className="d-flex only-one-offer">
                                        <div className="form-control-serch1">
                                            <Image src={OriginIcon} />
                                        </div>
                                        <div className="my-2 ms-2">
                                            <p
                                                className={`mb-0 text-primary text-sm fw-bold`}
                                            >
                                                ORIGIN
                                            </p>
                                        </div>
                                        <div className="ms-4 d-flex align-items-center">
                                            <Controller
                                                name={`address_origin.isInternationalPostalCode`}
                                                control={control}
                                                render={({
                                                    field: { onChange, value },
                                                }: any) => (
                                                    <>
                                                        <CheckBox
                                                            value={value}
                                                            id={`address_origin.isInternationalPostalCode`}
                                                            label={
                                                                'Manual Entry'
                                                            }
                                                            required={false}
                                                            checked={
                                                                isInternationalOriginPostalCode
                                                            }
                                                            onChangeFunc={(
                                                                e,
                                                            ) => {
                                                                addManuallyCityStateOrigin(
                                                                    e,
                                                                );
                                                            }}
                                                            disabled={
                                                                isDisabled
                                                            }
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="pt-2">
                                        <Controller
                                            name={`address_origin.street`}
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                            }: any) => (
                                                <>
                                                    <InputGroupText
                                                        onChangeFunc={onChange}
                                                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                        value={value}
                                                        tabIndex={5}
                                                        inputType={'text'}
                                                        placeholder={
                                                            'Street Address'
                                                        }
                                                        disabled={isDisabled}
                                                        groupInput={false}
                                                        id="address_origin.street"
                                                        inputName="address_origin.street"
                                                        errorText={
                                                            errors
                                                                ?.address_origin
                                                                ?.street
                                                                ?.message
                                                        }
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="pt-3 lg-pt-4">
                                        <Controller
                                            name={`address_origin.additional`}
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                            }: any) => (
                                                <>
                                                    <InputGroupText
                                                        onChangeFunc={onChange}
                                                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                        value={value}
                                                        tabIndex={6}
                                                        inputType={'text'}
                                                        placeholder={
                                                            'Additional'
                                                        }
                                                        disabled={isDisabled}
                                                        groupInput={false}
                                                        id="address_origin.additional"
                                                        inputName="address_origin.additional"
                                                        errorText={
                                                            errors
                                                                ?.address_origin
                                                                ?.additional
                                                                ?.message
                                                        }
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    {renderOriginAddressInputs}
                                    {/* <Row className="mt-3 mt-xl-4 d-flex">
                                        <Col xl={3} className="mb-3 mb-xl-0">
                                            <Controller
                                                name={`address_origin.postalCode`}
                                                control={control}
                                                render={({
                                                    field: { onChange, value },
                                                }: any) => (
                                                    <>
                                                        <InputGroupText
                                                            onChangeFunc={
                                                                onChange
                                                            }
                                                            className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                            value={value}
                                                            tabIndex={7}
                                                            id={`address_origin.postalCode`}
                                                            inputType={'text'}
                                                            placeholder={
                                                                'Postal Code'
                                                            }
                                                            disabled={
                                                                isDisabled
                                                            }
                                                            groupInput={false}
                                                            errorText={
                                                                errors
                                                                    ?.address_origin
                                                                    ?.postalCode
                                                                    ?.message ??
                                                                zipOriError
                                                            }
                                                        />
                                                    </>
                                                )}
                                            />
                                        </Col>
                                        <Col
                                            md={12}
                                            xl={6}
                                            className="mb-3 mb-xl-0"
                                        >
                                            <Controller
                                                name={`address_origin.city`}
                                                control={control}
                                                render={({
                                                    field: { onChange, value },
                                                }: any) => (
                                                    <ReactSelectGroup
                                                        value={cityArr.find(
                                                            (c: any) =>
                                                                c?.value ===
                                                                parseInt(value),
                                                        )}
                                                        labelShow={false}
                                                        inputClassName="mfnw__input-group pt-1 pt-lg-3"
                                                        options={cityArr}
                                                        placeholder={'City'}
                                                        onInputChange={(
                                                            valueChange: any,
                                                        ) => {
                                                            if (
                                                                valueChange !==
                                                                ''
                                                            ) {
                                                                var set: any = {
                                                                    oriCity:
                                                                        valueChange,
                                                                    oriState:
                                                                        '',
                                                                };
                                                                setSearchObj(
                                                                    set,
                                                                );
                                                            }
                                                        }}
                                                        onChangeFunc={(
                                                            valueStateTownChange: any,
                                                        ) => {
                                                            setValue(
                                                                'address_origin.city',
                                                                valueStateTownChange?.label,
                                                            );

                                                            setValue(
                                                                'address_origin.city_id',
                                                                valueStateTownChange?.value,
                                                            );
                                                        }}
                                                        tabIndex={2}
                                                        className="w-100"
                                                        isDisabled={
                                                            isDisabled ||
                                                            (!zipOriError &&
                                                                getValues(
                                                                    'address_origin.postalCode',
                                                                ) === null) ||
                                                            cityArr.length ===
                                                                1 ||
                                                            (zipOriError
                                                                ? true
                                                                : false)
                                                        }
                                                        isSearchable={
                                                            zipOriError
                                                                ? true
                                                                : false
                                                        }
                                                        errorText={
                                                            errors
                                                                ?.address_origin
                                                                ?.city?.message
                                                        }
                                                    />
                                                )}
                                            />
                                        </Col>
                                        <Col md={12} xl={3}>
                                            <Controller
                                                name={`address_origin.stateTown`}
                                                control={control}
                                                render={({
                                                    field: { onChange, value },
                                                }: any) => (
                                                    <>
                                                        <InputGroupText
                                                            onChangeFunc={
                                                                onChange
                                                            }
                                                            className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                            value={value}
                                                            tabIndex={1}
                                                            id={`address_origin.postalCode`}
                                                            inputType={'text'}
                                                            placeholder={
                                                                'State'
                                                            }
                                                            disabled={true}
                                                            groupInput={false}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </Col>
                                    </Row> */}
                                </Col>
                                <Col md={6}>
                                    <div className="d-flex only-one-offer">
                                        <div className="form-control-serch1">
                                            <Image src={Destination} />
                                        </div>
                                        <div className="my-2 ms-2">
                                            <p
                                                className={`mb-0 text-primary text-sm fw-bold`}
                                            >
                                                DESTINATION
                                            </p>
                                        </div>
                                        <div className="ms-4 d-flex align-items-center">
                                            <Controller
                                                name={`address_destination.isInternationalPostalCode`}
                                                control={control}
                                                render={({
                                                    field: { onChange, value },
                                                }: any) => (
                                                    <>
                                                        <CheckBox
                                                            value={value}
                                                            id={`address_destination.isInternationalPostalCode`}
                                                            label={
                                                                'Manual Entry'
                                                            }
                                                            required={false}
                                                            checked={
                                                                isInternationalPostalCode
                                                            }
                                                            onChangeFunc={(
                                                                e,
                                                            ) => {
                                                                addManuallyCityState(
                                                                    e,
                                                                );
                                                            }}
                                                            disabled={
                                                                isDisabled
                                                            }
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="pt-2">
                                            <Controller
                                                name={`address_destination.street`}
                                                control={control}
                                                render={({
                                                    field: { onChange, value },
                                                }: any) => (
                                                    <>
                                                        <InputGroupText
                                                            onChangeFunc={
                                                                onChange
                                                            }
                                                            className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                            value={value}
                                                            tabIndex={8}
                                                            inputType={'text'}
                                                            placeholder={
                                                                'Street Address'
                                                            }
                                                            disabled={
                                                                isDisabled
                                                            }
                                                            groupInput={false}
                                                            id="address_destination.street"
                                                            inputName="address_destination.street"
                                                            errorText={
                                                                errors
                                                                    ?.address_destination
                                                                    ?.street
                                                                    ?.message
                                                            }
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>
                                        <div className="pt-3">
                                            <Controller
                                                name={`address_destination.additional`}
                                                control={control}
                                                render={({
                                                    field: { onChange, value },
                                                }: any) => (
                                                    <>
                                                        <InputGroupText
                                                            onChangeFunc={
                                                                onChange
                                                            }
                                                            className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                            value={value}
                                                            tabIndex={9}
                                                            inputType={'text'}
                                                            placeholder={
                                                                'Additional'
                                                            }
                                                            disabled={
                                                                isDisabled
                                                            }
                                                            groupInput={false}
                                                            id="address_destination.additional"
                                                            inputName="address_destination.additional"
                                                            errorText={
                                                                errors
                                                                    ?.address_destination
                                                                    ?.additional
                                                                    ?.message
                                                            }
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>
                                        {renderAddressInputs}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} lg={6}>
                                    <p className="text-dark text-sm fw-semibold mt-4">
                                        HAUL INFORMATION
                                    </p>
                                    <Row>
                                        <Col
                                            md={6}
                                            xl={4}
                                            className="pb-3 pb-lg-0"
                                        >
                                            <Controller
                                                name={
                                                    'line_haule_data.discount'
                                                }
                                                control={control}
                                                render={({
                                                    field: { onChange, value },
                                                }: any) => (
                                                    <InputGroupText
                                                        onChangeFunc={onChange}
                                                        value={value}
                                                        className="mfnw__offer_form-control"
                                                        id="line_haule_data.discount"
                                                        inputName="line_haule_data.discount"
                                                        label="DISC %"
                                                        tabIndex={11}
                                                        inputType="number"
                                                        inputGroupClassName="mfnw__input-group-haul"
                                                        placeholder="DISC %"
                                                        disabled={isDisabled}
                                                        errorText={
                                                            errors
                                                                ?.line_haule_data
                                                                ?.discount
                                                                ?.message
                                                        }
                                                    />
                                                )}
                                            />
                                        </Col>
                                        <Col
                                            md={6}
                                            xl={4}
                                            className="mb-3 mb-xl-0"
                                        >
                                            <Controller
                                                name={'line_haule_data.miles'}
                                                control={control}
                                                render={({
                                                    field: { onChange, value },
                                                }: any) => (
                                                    <InputGroupText
                                                        onChangeFunc={onChange}
                                                        value={value}
                                                        className="mfnw__offer_form-control"
                                                        id="line_haule_data.miles"
                                                        inputName="line_haule_data.miles"
                                                        label="MILES"
                                                        tabIndex={12}
                                                        inputType="number"
                                                        inputGroupClassName="mfnw__input-group-haul"
                                                        placeholder="MILES"
                                                        disabled={isDisabled}
                                                        errorText={
                                                            errors
                                                                ?.line_haule_data
                                                                ?.miles?.message
                                                        }
                                                    />
                                                )}
                                            />
                                        </Col>
                                        <Col
                                            md={6}
                                            xl={4}
                                            className="pb-3 pb-lg-0"
                                        >
                                            <Controller
                                                name={'estimatedWeight'}
                                                control={control}
                                                render={({
                                                    field: { onChange, value },
                                                }: any) => (
                                                    <InputGroupText
                                                        onChangeFunc={onChange}
                                                        value={value}
                                                        className="mfnw__offer_form-control"
                                                        id="estimatedWeight"
                                                        inputName="estimatedWeight"
                                                        label="Weight"
                                                        tabIndex={13}
                                                        inputType="number"
                                                        inputGroupClassName="mfnw__input-group-haul"
                                                        placeholder="Weight"
                                                        disabled={isDisabled}
                                                        errorText={
                                                            errors
                                                                ?.estimatedWeight
                                                                ?.message
                                                        }
                                                    />
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="pt-1 pt-lg-3">
                                        <Controller
                                            name={'line_haule_data.notes'}
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                            }: any) => (
                                                <InputGroupText
                                                    value={value ? value : ''}
                                                    inputName={
                                                        'line_haule_data.notes'
                                                    }
                                                    inputType={'text'}
                                                    placeholder={'Enter Note'}
                                                    asTextarea="textarea"
                                                    onChangeFunc={(e: any) =>
                                                        changeNote(e)
                                                    }
                                                    className={`mfnw__offer_form-control mb-3${
                                                        (value === 'None' ||
                                                            value === 'none') &&
                                                        ' text-danger'
                                                    }`}
                                                    id="line_haule_data.notes"
                                                    tabIndex={14}
                                                    disabled={isDisabled}
                                                    errorText={
                                                        errors?.line_haule_data
                                                            ?.notes?.message
                                                    }
                                                />
                                            )}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6} className="mt-2 mt-lg-5">
                                    <Row className="mb-3 mb-xl-3 align-items-center d-flex flex-wrap">
                                        <Col
                                            xs={12}
                                            sm={6}
                                            md={5}
                                            className="text-left"
                                        >
                                            <span className="text-base fw-normal m-auto pe-4 pe-lg-2 text-truncate">
                                                Storage in Transit (SIT)
                                            </span>
                                        </Col>
                                        <Col
                                            xs={12}
                                            sm={6}
                                            md={7}
                                            className="mt-2 mt-md-0"
                                        >
                                            <div className="d-flex align-items-start storage-details flex-column">
                                                <div className="d-flex">
                                                    <Controller
                                                        name="line_haule_data.SIT_status"
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                value,
                                                            },
                                                        }: any) => (
                                                            <SwitchRadioButton
                                                                className="btn-check"
                                                                name="line_haule_data.SIT_status"
                                                                id="inline-radio-sit-status-no"
                                                                checked={
                                                                    value ===
                                                                        'No' ||
                                                                    value.toString() ===
                                                                        '0'
                                                                }
                                                                label="No"
                                                                onChangeFunc={
                                                                    onChange
                                                                }
                                                                value={0}
                                                                classes="mfnw_one"
                                                                disabled={
                                                                    isDisabled
                                                                        ? !isAcceptable
                                                                        : isAcceptable
                                                                }
                                                                tabIndex={15}
                                                            />
                                                        )}
                                                    />
                                                    <Controller
                                                        name="line_haule_data.SIT_status"
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                value,
                                                            },
                                                        }: any) => (
                                                            <SwitchRadioButton
                                                                className="btn-check"
                                                                name="line_haule_data.SIT_status"
                                                                id="inline-radio-sit-status-yes"
                                                                checked={
                                                                    value ===
                                                                        'Yes' ||
                                                                    value.toString() ===
                                                                        '1'
                                                                }
                                                                label="Yes"
                                                                onChangeFunc={
                                                                    onChange
                                                                }
                                                                value="1"
                                                                classes="mfnw_second"
                                                                disabled={
                                                                    isDisabled
                                                                        ? !isAcceptable
                                                                        : isAcceptable
                                                                }
                                                                tabIndex={16}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col
                                            xs={12}
                                            xl={5}
                                            className="d-none d-lg-flex ms-md-0 frist_responsiveline mt-3 mt-xl-2"
                                        ></Col>
                                        <Col
                                            xs={12}
                                            xl={5}
                                            className="ms-md-0 frist_responsiveline mt-3"
                                        >
                                            <Controller
                                                name={'line_haule_data.SIT'}
                                                control={control}
                                                render={({
                                                    field: { onChange, value },
                                                }: any) => (
                                                    <InputGroupText
                                                        onChangeFunc={onChange}
                                                        value={value}
                                                        className="mfnw__offer_form-control me-xl-3"
                                                        id="SIT"
                                                        inputName="line_haule_data.SIT"
                                                        label="SIT %"
                                                        tabIndex={13}
                                                        inputType="number"
                                                        inputGroupClassName="mfnw__input-group-haul"
                                                        placeholder="Weight"
                                                        disabled={
                                                            isDisabled
                                                                ? watchSITstatus ===
                                                                      0 ||
                                                                  watchSITstatus.toString() ===
                                                                      '0'
                                                                    ? isDisabled
                                                                    : !isAcceptable
                                                                : isAcceptable ||
                                                                  watchSITstatus ===
                                                                      0 ||
                                                                  watchSITstatus.toString() ===
                                                                      '0'
                                                        }
                                                        errorText={
                                                            errors
                                                                ?.line_haule_data
                                                                ?.SIT?.message
                                                        }
                                                    />
                                                )}
                                            />
                                        </Col>
                                    </Row>

                                    <Row className="mb-3 mb-xl-3 align-items-center">
                                        <Col
                                            xs={12}
                                            sm={6}
                                            md={5}
                                            className="text-left"
                                        >
                                            <span className="text-base fw-normal m-auto pe-4 pe-lg-0 text-truncate">
                                                Non-Temp Storage (NTSR)
                                            </span>
                                        </Col>

                                        <Col
                                            xs={12}
                                            sm={6}
                                            md={7}
                                            className="mt-2 mt-md-0"
                                        >
                                            <div className="d-flex align-items-start storage-details flex-column">
                                                <div className="d-flex">
                                                    <Controller
                                                        name="line_haule_data.ntsr"
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                value,
                                                            },
                                                        }: any) => (
                                                            <SwitchRadioButton
                                                                className="btn-check"
                                                                name="line_haule_data.ntsr"
                                                                id="inline-radio-ntsr-no"
                                                                checked={
                                                                    value ===
                                                                        'No' ||
                                                                    value.toString() ===
                                                                        '0'
                                                                }
                                                                label="No"
                                                                onChangeFunc={
                                                                    onChange
                                                                }
                                                                value="0"
                                                                classes="mfnw_one"
                                                                disabled={
                                                                    isDisabled
                                                                        ? watchSITstatus ===
                                                                              1 ||
                                                                          watchSITstatus.toString() ===
                                                                              '1'
                                                                            ? isDisabled
                                                                            : !isAcceptable
                                                                        : isAcceptable ||
                                                                          watchSITstatus ===
                                                                              1 ||
                                                                          watchSITstatus.toString() ===
                                                                              '1'
                                                                }
                                                                tabIndex={15}
                                                            />
                                                        )}
                                                    />
                                                    <Controller
                                                        name="line_haule_data.ntsr"
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                value,
                                                            },
                                                        }: any) => (
                                                            <SwitchRadioButton
                                                                className="btn-check"
                                                                name="line_haule_data.ntsr"
                                                                id="inline-radio-ntsr-yes"
                                                                checked={
                                                                    value ===
                                                                        'Yes' ||
                                                                    value.toString() ===
                                                                        '1'
                                                                }
                                                                label="Yes"
                                                                onChangeFunc={
                                                                    onChange
                                                                }
                                                                value="1"
                                                                classes="mfnw_second"
                                                                disabled={
                                                                    isDisabled
                                                                        ? watchSITstatus ===
                                                                              1 ||
                                                                          watchSITstatus.toString() ===
                                                                              '1'
                                                                            ? isDisabled
                                                                            : !isAcceptable
                                                                        : isAcceptable ||
                                                                          watchSITstatus ===
                                                                              1 ||
                                                                          watchSITstatus.toString() ===
                                                                              '1'
                                                                }
                                                                tabIndex={16}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3 mb-xl-3 align-items-center">
                                        <Col
                                            xs={12}
                                            sm={6}
                                            md={5}
                                            className="text-left"
                                        >
                                            <span className="text-base fw-normal m-auto pe-4 pe-lg-0 text-truncate">
                                                Origin Agent (O/A)
                                            </span>
                                        </Col>
                                        <Col
                                            xs={12}
                                            sm={6}
                                            md={7}
                                            className="mt-2 mt-md-0"
                                        >
                                            <div className="d-flex align-items-start storage-details flex-column">
                                                <div className="d-flex">
                                                    <Controller
                                                        name="line_haule_data.isOriginAgentOnly"
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                value,
                                                            },
                                                        }: any) => (
                                                            <SwitchRadioButton
                                                                className="btn-check"
                                                                name="line_haule_data.isOriginAgentOnly"
                                                                id="inline-radio-isOriginAgentOnly-no"
                                                                checked={
                                                                    value ===
                                                                        'No' ||
                                                                    value.toString() ===
                                                                        '0'
                                                                }
                                                                label="No"
                                                                onChangeFunc={
                                                                    onChange
                                                                }
                                                                value="0"
                                                                classes="mfnw_one"
                                                                disabled={
                                                                    isDisabled
                                                                        ? !isAcceptable
                                                                        : isAcceptable
                                                                }
                                                                tabIndex={17}
                                                            />
                                                        )}
                                                    />
                                                    <Controller
                                                        name="line_haule_data.isOriginAgentOnly"
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                value,
                                                            },
                                                        }: any) => (
                                                            <SwitchRadioButton
                                                                className="btn-check"
                                                                name="line_haule_data.isOriginAgentOnly"
                                                                id="inline-radio-isOriginAgentOnly-yes"
                                                                checked={
                                                                    value ===
                                                                        'Yes' ||
                                                                    value.toString() ===
                                                                        '1'
                                                                }
                                                                label="Yes"
                                                                onChangeFunc={
                                                                    onChange
                                                                }
                                                                value="1"
                                                                classes="mfnw_second"
                                                                disabled={
                                                                    isDisabled
                                                                        ? !isAcceptable
                                                                        : isAcceptable
                                                                }
                                                                tabIndex={18}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3 mb-xl-3 align-items-center">
                                        <Col
                                            xs={12}
                                            sm={6}
                                            md={5}
                                            className="text-left"
                                        >
                                            <span className="text-base fw-normal m-auto pe-4 pe-lg-0 text-truncate">
                                                Haul Type
                                            </span>
                                        </Col>
                                        <Col
                                            xs={12}
                                            sm={6}
                                            md={7}
                                            className="mt-2 mt-md-0"
                                        >
                                            <div className="d-flex align-items-start storage-details flex-column">
                                                <div className="d-flex">
                                                    <Controller
                                                        name="line_haule_data.isSelfHaul"
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                value,
                                                            },
                                                        }: any) => (
                                                            <SwitchRadioButton
                                                                className="btn-check"
                                                                name="line_haule_data.isSelfHaul"
                                                                id="inline-radio-isSelfHaul-no"
                                                                checked={
                                                                    value ===
                                                                        'No' ||
                                                                    value.toString() ===
                                                                        '0'
                                                                }
                                                                label="Self Haul"
                                                                onChangeFunc={
                                                                    onChange
                                                                }
                                                                value="0"
                                                                classes="mfnw_one"
                                                                disabled={
                                                                    isDisabled
                                                                        ? !isAcceptable
                                                                        : isAcceptable
                                                                }
                                                                tabIndex={19}
                                                            />
                                                        )}
                                                    />
                                                    <Controller
                                                        name="line_haule_data.isSelfHaul"
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                value,
                                                            },
                                                        }: any) => (
                                                            <SwitchRadioButton
                                                                className="btn-check"
                                                                name="line_haule_data.isSelfHaul"
                                                                id="inline-radio-isSelfHaul-yes"
                                                                checked={
                                                                    value ===
                                                                        'Yes' ||
                                                                    value.toString() ===
                                                                        '1'
                                                                }
                                                                label="Line Haul"
                                                                onChangeFunc={
                                                                    onChange
                                                                }
                                                                value="1"
                                                                classes="mfnw_second"
                                                                disabled={
                                                                    isDisabled
                                                                        ? !isAcceptable
                                                                        : isAcceptable
                                                                }
                                                                tabIndex={20}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {isJobForm && (
                                <Row>
                                    <Col mg={6} lg={6} className="mt-2 mt-md-4">
                                        <Row>
                                            <Col
                                                md={6}
                                                xl={8}
                                                className="pb-3 pb-lg-0"
                                            >
                                                <Controller
                                                    name="offerStatus"
                                                    control={control}
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                        },
                                                    }: any) => (
                                                        <>
                                                            <ReactSelectGroup
                                                                value={(!offerId &&
                                                                isJobForm
                                                                    ? jobStatsArray
                                                                    : offersStatsArray
                                                                ).find(
                                                                    (c: any) =>
                                                                        c?.value ===
                                                                        value,
                                                                )}
                                                                name={
                                                                    'offerStatus'
                                                                }
                                                                inputClassName="mfnw__input-group"
                                                                options={
                                                                    !offerId &&
                                                                    isJobForm
                                                                        ? jobStatsArray
                                                                        : offersStatsArray
                                                                }
                                                                labelShow={
                                                                    false
                                                                }
                                                                tabIndex={21}
                                                                onChangeFunc={(
                                                                    stateValue: any,
                                                                ) => {
                                                                    onChangeSelect(
                                                                        stateValue,
                                                                        'offerStatus',
                                                                    );
                                                                }}
                                                                label={''}
                                                                isSearchable={
                                                                    false
                                                                }
                                                                isDisabled={
                                                                    isDisabled
                                                                }
                                                                placeholder="Offers Status"
                                                            />
                                                            {errors?.offerStatus
                                                                ?.message && (
                                                                <ErrorMsg
                                                                    errorText={
                                                                        errors
                                                                            ?.offerStatus
                                                                            ?.message
                                                                    }
                                                                ></ErrorMsg>
                                                            )}
                                                        </>
                                                    )}
                                                />
                                            </Col>
                                            {!isDisabled && (
                                                <Col xl={4} md={6}>
                                                    <Button
                                                        variant="success text-uppercase fw-semibold text-white"
                                                        className="mfnw_btn mfnw_changestatus_responsive"
                                                        type="submit"
                                                        tabIndex={22}
                                                        onClick={() => {
                                                            isChangeStatusRef.current =
                                                                true;
                                                        }}
                                                    >
                                                        {isCreateOffer ? (
                                                            <LoaderBtn />
                                                        ) : (
                                                            'Change Status'
                                                        )}
                                                    </Button>
                                                </Col>
                                            )}
                                        </Row>
                                    </Col>
                                </Row>
                            )}
                            {(isAcceptable ? isDisabled : !isDisabled) && (
                                <Row className="justify-content-center">
                                    <Col md={5} className="order-md-2">
                                        <Button
                                            variant="primary w-100 mt-3 mt-lg-4 text-uppercase fw-semibold rounded-2"
                                            type="submit"
                                            tabIndex={24}
                                            ref={submitButtonRef}
                                            onClick={() => {
                                                isChangeStatusRef.current =
                                                    false;
                                            }}
                                        >
                                            {isCreateOffer ? (
                                                <LoaderBtn />
                                            ) : (
                                                'Save'
                                            )}
                                        </Button>
                                    </Col>
                                    <Col md={5} className="order-md-1">
                                        <Button
                                            className="btn-cancel mt-3 mt-lg-4 w-100 text-uppercase fw-semibold rounded-2"
                                            onClick={() => onClose()}
                                            tabIndex={23}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                            {!isJobForm &&
                                (isAcceptable ? !isDisabled : isDisabled) && (
                                    <Row className="justify-content-center">
                                        <Col md={5}>
                                            <Button
                                                variant="primary"
                                                className="mt-4 w-100 text-uppercase fw-semibold rounded-2"
                                                onClick={() => {
                                                    handleAcceptActions(
                                                        offerId,
                                                        'accepted',
                                                    );
                                                }}
                                                tabIndex={23}
                                            >
                                                ACCEPT
                                            </Button>
                                        </Col>
                                        <Col md={5}>
                                            <Button
                                                className="btn-cancel  w-100 mt-4 text-uppercase fw-semibold rounded-2"
                                                tabIndex={24}
                                                onClick={() =>
                                                    handleAcceptActions(
                                                        offerId,
                                                        'rejected',
                                                    )
                                                }
                                            >
                                                {isRejectOffer ? (
                                                    <LoaderBtn />
                                                ) : (
                                                    'REJECT'
                                                )}
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                        </Form>
                    )}
                </Modal.Body>
            </Modal>
            {showPopupShipper && (
                <AddShipperInformation
                    showPopup={showPopupShipper}
                    handleClose={handleCloseShipper}
                    shipperName={shipperName}
                    setShipperNanoId={setShipperNanoId}
                    shipperNanoId={shipperNanoId}
                    setShippersData={setShippersData}
                    shippersData={shippersData}
                    isDisabled={isDisabled}
                />
            )}
            {showPopup && (
                <TspAddOrEditPopup
                    showPopup={showAddTSPPopup}
                    handleClose={handleAddTSPClose}
                    getDataListCall={() =>
                        getAllList(searchObj2, {
                            agentIssuedOffset: false,
                            agentOffset: false,
                            forwarderOffset: false,
                        })
                    }
                    editData={null}
                />
            )}

            {showNoteListPopup && (
                <ViewNoteListPopup
                    showPopup={showNoteListPopup}
                    handleClose={handleNoteListClose}
                    offerDataObj={editData}
                    getOfferData={getOfferData}
                    listCall={listCall}
                />
            )}

            {showHistoryListPopup && (
                <ViewHistoryListPopup
                    showPopup={showHistoryListPopup}
                    handleClose={handleHistoryListClose}
                    offerDataObj={editData}
                    getOfferData={getOfferData}
                    listCall={listCall}
                />
            )}

            {showViewListPopup && (
                <ViewDocumentListPopup
                    showPopup={showViewListPopup}
                    handleClose={handleViewListClose}
                    offerDataObj={editData}
                    getOfferData={getOfferData}
                    listCall={listCall}
                />
            )}
            {showScheduleListPopup && (
                <JobSchedulePopup
                    showPopup={showScheduleListPopup}
                    handleClose={handleScheduleListClose}
                    offerDataObj={editData}
                    getOfferData={getOfferData}
                    listCall={listCall}
                    isDisabled={!showEditSchedulePopup}
                    setShowEditSchedulePopup={setShowEditSchedulePopup}
                    showEditSchedulePopup={showEditSchedulePopup}
                    isEditable={isEditable}
                />
            )}
            {showAgentPopup && (
                <AgentsAddOrEditPopup
                    showPopup={showAgentPopup}
                    handleClose={handleAgentClose}
                    getDataListCall={() =>
                        getAllList(searchObj2, {
                            agentIssuedOffset: false,
                            agentOffset: false,
                            forwarderOffset: false,
                        })
                    }
                />
            )}
        </>
    );
};
export default AddOfferPopup;
