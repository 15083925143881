/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

import { useDispatch } from 'react-redux';
import moment from 'moment';
import { renderToString } from 'react-dom/server';

import { Col, Image, Row } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import { searchTimeOut, refreshInterval } from '@constants/general.const';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import Assign from '@images/assign.svg';
import Schedule from '@images/calendar-schedule.svg';
import Userlist from '@images/listuser.svg';
import Unschedule from '@images/unschedule.svg';
import Asideiconn from '@images/aside_allow.svg';
import Scheduleicon from '@images/Scheduled_icon.svg';
import Unscheduleicon from '@images/Unscheduled.svg';
import unload from '@images/unload.svg';
import load from '@images/load.svg';
import pack from '@images/pack.svg';

import { offerData } from '@app/OffersList/functions';
import { jobsCalenderList } from '@app/JobList/functions';
import CustomCalendarHeader from '@app/Schedule/JobCalender/CustomCalendarHeader';
import OfferRejectPopup from '@app/OffersList/offerRejectPopup';

import PackerDayTableView from './packerDayTableView';
import EventAccordion from './eventAccordion';
import { useOfferPopup } from '../../../constants/offer.const';
import { formatDateTOCurrent } from '../../../constants/general.const';
import JobInfo from '../jobInfo';

import '../schedule.scss';
import { useLocation } from 'react-router-dom';
import { useHolidayPopup } from '../../SettingsLists/Holidays/holidays.const';
import HolidaysAddPopup from '../../SettingsLists/Holidays/holidaysAddPopup';

const JobTabCalender = ({ type, setViewName, viewName }: any) => {
    const location = useLocation();
    const dispatch = useDispatch<any | null>();
    const calendarRef = useRef<FullCalendar | null>(null);
    const intervalRef = useRef<NodeJS.Timer | null>(null);

    const [showJobLInfoPopup, setShowJobLInfoPopup] = useState<boolean>(false);
    const [showEditJobLInfoPopup, setShowEditJobLInfoPopup] =
        useState<boolean>(false);
    const [isDayGridWeekView, setIsDayGridWeekView] = useState<boolean>(false);
    const [isDayGridView, setIsDayGridView] = useState<boolean>(false);
    const [weekViewHeight, setWeekViewHeight] = useState<string>('auto');
    const [currentEvents, setCurrentEvents] = useState<any[]>([]);
    const [packData, setPackData] = useState<any[]>([]);
    const [loadData, setLoadData] = useState<any[]>([]);
    const [unloadData, setUnloadData] = useState<any[]>([]);
    const [deliveryData, setDeliveryData] = useState<any[]>([]);
    const [pickupData, setPickupData] = useState<any[]>([]);
    const [driverArrData, setDriverArrData] = useState<any[]>([]);
    const [packerArrData, setPackerArrData] = useState<any[]>([]);
    const [dateRange, setDateRange] = useState<[any, any]>([null, null]);
    const [packLoadUnloadData, setPackLoadUnload] = useState<any | null>(null);
    const [packerName, setPackerName] = useState<any>(null);
    const [filter, setFilter] = useState<string>('all');
    const [filterText, setFilterText] = useState<string>('All');
    const [searchText, setSearchText] = useState<any>(null);
    const [currentDate, setCurrentDate] = useState<any>(null);

    //  ask fore type
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [currentPackerTitle, setCurrentPackerTitle] = useState<string | null>(
        '',
    );

    const {
        handleViewShow,
        handleClose,
        offerDataObj,
        offerId,
        popup,
        getOfferData,
        setOfferDataObj,
        renderViewPopup,
        renderEditPopup,
        showViewPopup,
        showEditPopup,
    } = useOfferPopup();
    const {
        handleViewShow: handleViewHolidayShow,
        showViewPopup: showViewHolidayPopup,
        handleViewClose: handleViewHolidayClose,
        getFilters: getFiltersHoliday,
        getList: getListHoliday,
        isDisabled: isDisabledHoliday,
        holidayData,
    } = useHolidayPopup();
    const updateCurrentPackerTitle = (newState: any) => {
        setCurrentPackerTitle(newState);
    };

    const handleJobLInfoShow = () => {
        setShowJobLInfoPopup(true);
    };
    const handleJobLInfoClose = () => {
        setShowJobLInfoPopup(false);
    };
    const handleJobEditInfoShow = () => {
        setShowEditJobLInfoPopup(true);
    };
    const handleJobEditInfoClose = () => {
        setShowEditJobLInfoPopup(false);
    };
    // dought
    const [selectedDate, setSelectedDate] = useState<any>(
        moment().format('YYYY-MM-DD'),
    );

    const fetchData = async (info: any) => {
        const { payload } = await dispatch(offerData(info.event.id));
        if (payload?.data?.message !== '' && payload?.data?.status) {
            setOfferDataObj(payload?.data?.data);
            setPackLoadUnload(info.event.value);
            setPackerName(info.event.packer_name);
            handleJobLInfoShow();
        }
    };

    const getFetchJobList = async (start: Date, end: Date) => {
        setIsLoading(true);
        setCurrentEvents([]);
        const { payload } = await dispatch(
            jobsCalenderList({
                startDate: formatDateTOCurrent(start),
                endDate: formatDateTOCurrent(end),
                type: type,
                searchText: searchText,
                filter: filter,
            }),
        );
        if (
            payload?.data?.message !== '' &&
            payload?.data?.status &&
            Array.isArray(payload?.data?.dataList)
        ) {
            const updatedEvents = payload?.data?.dataList.reduce(
                (accumulator: any, event: any) => {
                    const startDate = new Date(event.start);
                    const endDate = new Date(event.end);
                    while (startDate <= endDate) {
                        accumulator.push({
                            start: moment
                                .utc(startDate)
                                .local()
                                .format('Y-MM-DD'),
                            title: event.title,
                            allDay: true,
                            id: event.id,
                            icon: event.icon,
                            classNames: event.classNames,
                            backgroundColor: event.backgroundColor,
                            pack_load_unload: event.pack_load_unload,
                            titleWithDestination: event.titleWithDestination,
                        });
                        startDate.setDate(startDate.getDate() + 1);
                    }
                    return accumulator;
                },
                [],
            );
            if (
                payload?.data?.pack &&
                payload?.data?.load &&
                payload?.data?.unloading &&
                payload?.data?.dilevery &&
                payload?.data?.pickup &&
                payload?.data?.packerArr &&
                payload?.data?.driverArr
            ) {
                setPackData(payload?.data?.pack);
                setLoadData(payload?.data?.load);
                setUnloadData(payload?.data?.unloading);
                setDeliveryData(payload?.data?.dilevery);
                setPickupData(payload?.data?.pickup);
                setPackerArrData(payload?.data?.packerArr);
                setDriverArrData(payload?.data?.driverArr);
            }
            setCurrentEvents(updatedEvents);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        const getData: any = setTimeout(() => {
            (searchText !== null || filter !== null) &&
                dateRange[0] &&
                dateRange[1] &&
                getFetchJobList(dateRange[0], dateRange[1]);
        }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [searchText, filter]);

    const eventContent = (arg: any) => {
        const containerElement = document.createElement('div');

        const iconElement = document.createElement('span');
        iconElement.className = arg.event.backgroundColor;
        const icon =
            arg.event.extendedProps.icon === 'Schedule'
                ? 'S'
                : arg.event.extendedProps.icon === 'Unschedule'
                ? 'U'
                : arg.event.extendedProps.icon === 'inSIT'
                ? 'S'
                : arg.event.extendedProps.icon === 'Completed'
                ? 'C'
                : arg.event.extendedProps.icon === 'SIT Delivery'
                ? 'D'
                : arg.event.extendedProps.icon === 'Holiday'
                ? 'H'
                : 'A';

        const iconTitle =
            arg.event.extendedProps.icon === 'Schedule'
                ? 'Scheduled'
                : arg.event.extendedProps.icon === 'Unschedule'
                ? 'Unscheduled'
                : arg.event.extendedProps.icon === 'inSIT'
                ? 'inSIT'
                : arg.event.extendedProps.icon === 'Completed'
                ? 'Completed'
                : arg.event.extendedProps.icon === 'SIT Delivery'
                ? 'SIT Delivery'
                : 'Assigned';

        iconElement.innerHTML = icon;
        iconElement.setAttribute('title', iconTitle);

        // const iconComponent = (
        //     <Image src={icon} className="align-text-bottom" />
        // );

        containerElement.appendChild(iconElement);
        const titleElement = document.createElement('span');
        containerElement.setAttribute(
            'title',
            arg?.event?.extendedProps?.titleWithDestination ?? arg.event.title,
        );
        titleElement.appendChild(document.createTextNode(arg.event.title));
        titleElement.className = 'text-uppercase px-2';
        containerElement.appendChild(titleElement);
        // iconElement.innerHTML = renderToString(iconComponent);

        containerElement.className = arg.event.classNames;

        return { domNodes: [containerElement] };
    };

    useEffect(() => {
        const calendarApi = calendarRef.current?.getApi();

        if (calendarApi) {
            const onViewDatesSet = () => {
                setIsDayGridWeekView(
                    calendarApi.view.type === 'customWeekView',
                );
                setIsDayGridView(calendarApi.view.type === 'timeGridDay');
            };
            calendarApi.on('datesSet', onViewDatesSet);

            return () => {
                calendarApi.off('datesSet', onViewDatesSet);
            };
        }
    }, []);

    const handleViewChange = (viewType: any) => {
        if (viewType.view.type === 'customWeekView') {
            const ViewName =
                type === 'job'
                    ? 'Weekly Job View'
                    : type === 'packer'
                    ? 'Weekly Packer View'
                    : 'Weekly Driver View';

            setViewName(ViewName);
            var weekElementsWithClass = document.querySelectorAll(
                '.fc-scrollgrid-section-body',
            );
            setPackerArrData([]);
            setDriverArrData([]);
            const lastElement =
                weekElementsWithClass[weekElementsWithClass.length - 1];
            lastElement.classList.add('d-none');
        } else {
            var ViewName = null;
            if (viewType.view.type === 'timeGridDay') {
                ViewName =
                    type === 'job'
                        ? 'Daily Job View'
                        : type === 'packer'
                        ? 'Daily Packer View'
                        : type === 'driver'
                        ? 'Daily Driver View'
                        : type === 'sit'
                        ? 'Daily SIT View'
                        : 'Daily SIT View';
            } else {
                ViewName =
                    type === 'job'
                        ? 'Monthly Job View'
                        : type === 'packer'
                        ? 'Monthly Packer View'
                        : type === 'driver'
                        ? 'Monthly Driver View'
                        : type === 'sit'
                        ? 'Monthly SIT View'
                        : 'Monthly Completed View';
            }

            setViewName(ViewName);
            setWeekViewHeight('auto');
            var elementsWithClass = document.querySelectorAll(
                '.fc-scrollgrid-section-body',
            );
            setPackerArrData([]);
            setDriverArrData([]);
            const lastElement = elementsWithClass[elementsWithClass.length - 1];
            lastElement.classList.remove('d-none');
        }
    };

    const renderWeekHeader = ({ date }: any) => {
        const dayName = date.toLocaleString('en-US', { weekday: 'short' });
        const monthName = date.toLocaleString('en-US', { month: 'short' });
        const dayOfMonth = date.getDate();

        const matchingEvents = currentEvents.filter(
            (event: any) =>
                event.start === moment.utc(date).local().format('Y-MM-DD'),
        );

        return (
            <div className="fc-scrollgrid-sync-inner text-decoration-none">
                <div className="day-name fw-bolder fs-5">{dayName}</div>
                <div className="text-light text-base fw-normal">
                    <div className="text-center ps-1">
                        {dayOfMonth} {monthName}
                    </div>
                    <div className="text-end">
                        <FontAwesomeIcon icon={faUser} width={10} />
                        <span className="user-count fs-7 px-1">0</span>
                    </div>
                </div>
                <div className="event-list text-start w-100">
                    {matchingEvents.map((event) => (
                        <div
                            className={` mb-2 ${event.classNames}`}
                            key={event.id}
                        >
                            <span className={`${event.backgroundColor}`}>
                                {event?.icon === 'Assign'
                                    ? 'A'
                                    : event?.icon === 'Unschedule'
                                    ? 'U'
                                    : event?.icon === 'inSIT'
                                    ? 'S'
                                    : event?.icon === 'Completed'
                                    ? 'C'
                                    : event?.icon === 'SIT Delivery'
                                    ? 'D'
                                    : event?.icon === 'Holiday'
                                    ? 'H'
                                    : 'S'}
                            </span>
                            <span className="text-uppercase">
                                {' '}
                                {event.title}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderDayHeader = ({ date }: any) => {
        const dayName = moment.utc(date).format('ddd');

        return (
            <div className="fc-scrollgrid-sync-inner py-2">
                <div className="day-name">{dayName}</div>
            </div>
        );
    };

    const headerToolbar = {
        start: 'today prev,next title',
        end: 'dayGridMonth,customWeekView,timeGridDay',
    };
    const titleFormatSet: any = {
        year: 'numeric',
        month: 'long',
    };

    if (type === 'packer' && isDayGridWeekView) {
        headerToolbar.start = 'today prev,customRangeButton,next title';
    }

    if (isDayGridView) {
        headerToolbar.start = 'today prev,customRangeButton,next title';
        const button = document.querySelector('.fc-customRangeButtons-button');
        const existingImage = button?.querySelector('img');
        if (!existingImage) {
            const containerElement = document.createElement('img');
            containerElement.setAttribute('src', Userlist);
            button?.appendChild(containerElement);
        }
    }

    const jobInfoComponent = useMemo(() => {
        if (showEditJobLInfoPopup && packerArrData) {
            return (
                <JobInfo
                    showPopup={showEditJobLInfoPopup}
                    onClose={handleJobEditInfoClose}
                    offerDataObj={offerDataObj}
                    packLoadUnloadData={packLoadUnloadData}
                    packerName={packerName}
                    isDisabled={false}
                    getOfferData={getOfferData}
                    getFetchJobList={() => {
                        getFetchJobList(dateRange[0], dateRange[1]);
                    }}
                    packerArrData={packerArrData}
                    currentPackerTitle={currentPackerTitle}
                />
            );
        }
        return null;
    }, [
        showEditJobLInfoPopup,
        offerDataObj,
        packLoadUnloadData,
        packerName,
        packerArrData,
        currentPackerTitle,
    ]);

    useEffect(() => {
        if (isDayGridView && calendarRef.current) {
            calendarRef.current.getApi().gotoDate(selectedDate);
        }
    }, [selectedDate]);

    useEffect(() => {
        if (location.pathname === '/schedule' && calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            const dateInfo = calendarApi?.view?.activeStart;
            const dateInfo2 = calendarApi?.view?.activeEnd;

            if (dateInfo && dateInfo2) {
                intervalRef.current = setInterval(() => {
                    getFetchJobList(dateInfo, dateInfo2);
                }, refreshInterval);
            }
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [location.pathname]);

    return (
        <>
            <div className={`demo-app ${type}`}>
                {isLoading ? (
                    <div className="my-5">
                        <LoaderBtn />
                    </div>
                ) : null}

                <div
                    className={
                        isLoading
                            ? 'd-none'
                            : isDayGridWeekView
                            ? 'demo-app-main week-view'
                            : isDayGridView
                            ? 'demo-app-main day-view position-relative'
                            : 'demo-app-main month-view'
                    }
                >
                    <div className="fc">
                        <div className="fc-toolbar">
                            <CustomCalendarHeader
                                calendarRef={calendarRef}
                                setCurrentDate={setCurrentDate}
                                viewName={viewName}
                                currentDate={currentDate}
                                searchText={searchText}
                                setSearchText={setSearchText}
                                filter={filter}
                                filterText={filterText}
                                setFilterText={setFilterText}
                                setFilter={setFilter}
                                isDayGridView={isDayGridView}
                                setDateRange={setDateRange}
                                setSelectedDate={setSelectedDate}
                                dateRange={dateRange}
                                type={type}
                                isDayGridWeekView={isDayGridWeekView}
                            />
                        </div>
                    </div>
                    <div className="mfnw_calendar_scrolling_parent">
                        <div className="mfnw_calendar_scrolling">
                            <FullCalendar
                                ref={calendarRef}
                                firstDay={1}
                                initialDate={selectedDate}
                                eventOverlap={false}
                                eventClick={(info) => {
                                    if (
                                        info?.event?.extendedProps?.icon ==
                                        'Holiday'
                                    ) {
                                        handleViewHolidayShow(info.event.id);
                                    } else {
                                        handleViewShow(info.event.id);
                                    }
                                }}
                                eventContent={eventContent}
                                datesSet={(dateInfo) => {
                                    setCurrentEvents([]);
                                    handleViewChange(dateInfo);
                                    getFetchJobList(
                                        dateInfo.start,
                                        dateInfo.end,
                                    );

                                    setDateRange([
                                        dateInfo.start,
                                        dateInfo.end,
                                    ]);
                                    setCurrentDate(dateInfo.view.title);
                                }}
                                plugins={[
                                    dayGridPlugin,
                                    timeGridPlugin,
                                    interactionPlugin,
                                    listPlugin,
                                ]}
                                initialView="dayGridMonth"
                                headerToolbar={false}
                                customButtons={{
                                    customRangeButton: {
                                        text: ``,
                                    },
                                    customRangeButtons: {},
                                }}
                                events={currentEvents}
                                nowIndicator={false}
                                navLinks={true}
                                views={{
                                    customWeekView: {
                                        type: 'timeGridWeek',

                                        allDayText: '',
                                        allDayClassNames:
                                            'w-100 px-5 fw-bolder fs-5 text-light d-none',
                                    },
                                }}
                                titleFormat={titleFormatSet}
                                dayHeaderContent={
                                    isDayGridWeekView
                                        ? renderWeekHeader
                                        : renderDayHeader
                                }
                                dayCellClassNames={
                                    isDayGridWeekView ? 'd-none' : 'custom-cell'
                                }
                                height={weekViewHeight}
                            />

                            {isDayGridWeekView && (
                                <>
                                    {type === 'job' && (
                                        <>
                                            <EventAccordion
                                                data={packData}
                                                title={'Pack'}
                                                type={type}
                                                fetchData={fetchData}
                                                handleViewShow={handleViewShow}
                                            />
                                            <hr className="m-0 p-0 text-white" />
                                            <EventAccordion
                                                data={loadData}
                                                title={'Load'}
                                                type={type}
                                                fetchData={fetchData}
                                                handleViewShow={handleViewShow}
                                            />
                                            <hr className="m-0 p-0 text-white" />
                                            <EventAccordion
                                                data={unloadData}
                                                title={'UnLoad'}
                                                type={type}
                                                fetchData={fetchData}
                                                handleViewShow={handleViewShow}
                                            />
                                            <hr className="m-0 p-0 text-white" />
                                            <EventAccordion
                                                data={pickupData}
                                                title={'PickUp'}
                                                type={type}
                                                fetchData={fetchData}
                                                handleViewShow={handleViewShow}
                                            />
                                            <hr className="m-0 p-0 text-white" />
                                            <EventAccordion
                                                data={deliveryData}
                                                title={'Delivery'}
                                                type={type}
                                                fetchData={fetchData}
                                                handleViewShow={handleViewShow}
                                            />
                                        </>
                                    )}
                                    {type === 'packer' && (
                                        <>
                                            <EventAccordion
                                                data={packerArrData}
                                                title={'PACKER'}
                                                type={type}
                                                fetchData={fetchData}
                                                handleViewShow={handleViewShow}
                                                updateCurrentPackerTitle={
                                                    updateCurrentPackerTitle
                                                }
                                            />
                                            <hr className="m-0 p-0 text-white" />
                                        </>
                                    )}
                                    {type === 'driver' && (
                                        <>
                                            <EventAccordion
                                                data={driverArrData}
                                                title={'Driver'}
                                                type={type}
                                                fetchData={fetchData}
                                                handleViewShow={handleViewShow}
                                            />
                                            <hr className="m-0 p-0 text-white" />
                                        </>
                                    )}
                                </>
                            )}
                            {isDayGridView && (
                                <>
                                    {type === 'packer' && (
                                        <PackerDayTableView
                                            packerArrData={packerArrData}
                                            dateRange={dateRange}
                                            type={type}
                                        />
                                    )}
                                    {type === 'driver' && (
                                        <PackerDayTableView
                                            packerArrData={driverArrData}
                                            dateRange={dateRange}
                                            type={type}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {showJobLInfoPopup && (
                    <JobInfo
                        showPopup={showJobLInfoPopup}
                        onClose={handleJobLInfoClose}
                        offerDataObj={offerDataObj}
                        packLoadUnloadData={packLoadUnloadData}
                        packerName={packerName}
                        isDisabled={true}
                        handleEditShow={handleJobEditInfoShow}
                        packerArrData={packerArrData}
                        currentPackerTitle={currentPackerTitle}
                    />
                )}
                {jobInfoComponent}
            </div>
            {!isLoading && (
                <>
                    {isDayGridWeekView ? (
                        <>
                            <div className="mt-3">
                                <Row className="align-items-center mb-md-3 mb-sm-1">
                                    <Col sm={8} md={4}></Col>
                                    <Col xs={3} md={4}>
                                        <div className="row_last text-center d-flex justify-content-center">
                                            <div className="responsive_right_side">
                                                <Image src={Asideiconn} />
                                                <span className="event_font">
                                                    Assigned
                                                </span>
                                            </div>
                                            <div className="responsive_right_side">
                                                <Image src={Scheduleicon} />
                                                <span className="event_font">
                                                    Scheduled
                                                </span>
                                            </div>
                                            <div className="responsive_right_side">
                                                <Image src={Unscheduleicon} />
                                                <span className="event_font">
                                                    Unscheduled
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={3} md={4}>
                                        {type === 'packer' && (
                                            <div className="d-flex align-items-center justify-content-end text-center">
                                                <div className="element_box_footer me-2 d-flex">
                                                    <Image
                                                        src={pack}
                                                        className="align-items-end"
                                                    />
                                                </div>
                                                <span className="pt-2 pm-2 text-base text-dark fw-bold">
                                                    Pack
                                                </span>
                                                <div className="ps-3 d-flex">
                                                    <div className="element_box_footer me-2">
                                                        <Image
                                                            src={load}
                                                            className="align-items-end"
                                                        />
                                                    </div>
                                                    <span className="pt-2 pm-2 text-base text-dark fw-bold">
                                                        Load
                                                    </span>
                                                </div>
                                                <div className="ps-3 d-flex">
                                                    <div className="element_box_footer_last me-2">
                                                        <Image
                                                            src={unload}
                                                            className="align-items-end"
                                                        />
                                                    </div>
                                                    <span className="pt-2 pm-2 text-base text-dark fw-bold">
                                                        Unload
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        </>
                    ) : (
                        <Row className="align-items-center mb-md-3 mb-sm-1">
                            <Col sm={8} md={3}></Col>
                            <Col sm={8} md={6}>
                                <div className="row_last mt-2 mt-lg-3 text-center d-flex justify-content-center">
                                    <div className="d-flex responsive_right_side me-3 align-items-center">
                                        <div className="event_orange me-2"></div>
                                        <p className="event_font  m-0 p-0">
                                            Assigned
                                        </p>
                                    </div>
                                    <div className="d-flex responsive_right_side me-3 align-items-center">
                                        <div className="event_blue me-2"></div>
                                        <p className="event_font m-0 p-0">
                                            Scheduled
                                        </p>
                                    </div>
                                    <div className="d-flex responsive_right_side me-3 align-items-center">
                                        <div className="event_light_blue me-2"></div>
                                        <p className="event_font m-0 p-0">
                                            SIT Delivery
                                        </p>
                                    </div>
                                    <div className="d-flex responsive_right_side me-3 align-items-center">
                                        <div className="event_red me-2"></div>
                                        <p className="event_font  m-0 p-0">
                                            {type === 'sit'
                                                ? 'SIT'
                                                : 'Unscheduled'}
                                        </p>
                                    </div>
                                    <div className="d-flex responsive_right_side me-3 align-items-center">
                                        <div className="event_green me-2"></div>
                                        <p className="event_font  m-0 p-0">
                                            Completed
                                        </p>
                                    </div>
                                    <div className="d-flex responsive_right_side me-3 align-items-center">
                                        <div className="event_holiday me-2"></div>
                                        <p className="event_font  m-0 p-0">
                                            Holiday
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={8} md={3}>
                                {type === 'packer' && (
                                    <div className="d-flex  mt-2 mt-lg-3 text-center align-items-center justify-content-end text-center">
                                        <div className="element_box_footer me-2 d-flex">
                                            <Image
                                                src={pack}
                                                className="align-items-end"
                                            />
                                        </div>
                                        <span className="pt-2 pm-2 text-base text-dark fw-bold">
                                            Pack
                                        </span>
                                        <div className="ps-3 d-flex">
                                            <div className="element_box_footer me-2">
                                                <Image
                                                    src={load}
                                                    className="align-items-end"
                                                />
                                            </div>
                                            <span className="pt-2 pm-2 text-base text-dark fw-bold">
                                                Load
                                            </span>
                                        </div>
                                        <div className="ps-3 d-flex">
                                            <div className="element_box_footer_last me-2">
                                                <Image
                                                    src={unload}
                                                    className="align-items-end"
                                                />
                                            </div>
                                            <span className="pt-2 pm-2 text-base text-dark fw-bold">
                                                Unload
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    )}
                </>
            )}

            {showViewPopup && renderViewPopup('View Job', true, () => {})}
            {/* {showViewHolidayPopup &&
                renderViewHolidayPopup('View Holiday', true, () => {})} */}
            {showViewHolidayPopup && (
                <HolidaysAddPopup
                    showPopup={showViewHolidayPopup}
                    handleClose={handleViewHolidayClose}
                    getDataListCall={() => getListHoliday(1, getFiltersHoliday)}
                    editData={holidayData}
                    isDisabled={isDisabledHoliday}
                    modelTitle={'View Holiday'}
                    // handleEditShow={handleEditShow}
                    // showAlert={showAlert}
                />
            )}
            {showEditPopup &&
                renderEditPopup('Edit Job', true, () =>
                    getFetchJobList(dateRange[0], dateRange[1]),
                )}

            <OfferRejectPopup
                handleClose={handleClose}
                popup={popup}
                offerId={offerId}
            />
        </>
    );
};

export default JobTabCalender;
