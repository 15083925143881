import React from 'react';
import { Button } from 'react-bootstrap';

const StatusButtons = ({ status }: { status: string }) => (
    <>
        {status === 'accepted' ? (
            <Button className="btn-unschedule rounded-pill text-white fw-semibold py-2 text-truncate">
                Unscheduled
            </Button>
        ) : status === 'scheduled' ? (
            <Button
                variant="primary"
                className="rounded-pill text-white fw-semibold py-2 text-truncate"
            >
                Scheduled
            </Button>
        ) : status === 'completed' ? (
            <Button
                variant="success"
                className="rounded-pill text-white fw-semibold py-2 text-truncate"
            >
                Completed
            </Button>
        ) : status === 'assigned' ? (
            <Button className="btn-canceled rounded-pill text-white fw-semibold py-2 text-truncate">
                Assigned
            </Button>
        ) : status === 'inSIT' ? (
            <Button className="btn-canceled rounded-pill text-white fw-semibold py-2 text-truncate">
                In SIT
            </Button>
        ) : status === 'sit_delivery' ? (
            <Button className="btn-sit-delivery rounded-pill text-white fw-semibold py-2 text-truncate">
                SIT Delivery
            </Button>
        ) : (
            <Button className="btn-canceled rounded-pill text-white fw-semibold py-2 text-truncate">
                Canceled
            </Button>
        )}
    </>
);

export default StatusButtons;
